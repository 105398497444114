import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import AssetsSelect from 'components/common/controls/custom-select/assets-select';

import FormItem from '../form-item';

export const FormAssetSelect = ({
  rules,
  name,
  label,
  itemProps,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => (
          <AssetsSelect value={value} onChange={onChange} {...props} />
        )}
      />
    </FormItem>
  );
};

FormAssetSelect.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object,
  isLinkToElement: PropTypes.bool
};

FormAssetSelect.defaultProps = {
  rules: {},
  itemProps: {},
  isLinkToElement: false
};

export default FormAssetSelect;
