import React from 'react';
import { useTranslation } from 'react-i18next';

import { RESPONSIBLE } from 'constants/index';

import Typography from 'components/common/typography';
import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';
// eslint-disable-next-line import/no-cycle
import FormProjectParticipantsSelect from 'components/common/hook-form/select/project-participants';
import {
  FormEmployeeSelect,
  validateRequired
} from 'components/common/hook-form';

const { Text } = Typography;

const ActionTypeResponsible = ({ projectId, isMulti, isDisabled }) => {
  const { t } = useTranslation('AddTask');

  const SelectEmployee = projectId
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;

  const label = (
    <>
      <Text
        style={{ color: 'inherit', marginRight: 5 }}
        data-qa="qa-pr8a8u5st2dyfs9"
        size="small"
      >
        {t('TaskResponsible')}
      </Text>

      {isMulti && (
        <Tooltip title={t('SeveralResponsiblesTip')}>
          <Icon
            type="question-circle"
            size={12}
            style={{ verticalAlign: 'unset' }}
          />
        </Tooltip>
      )}
    </>
  );

  return (
    <SelectEmployee
      name={RESPONSIBLE}
      label={label}
      valueText={t('EnterYourNameEmail')}
      rules={{
        required: validateRequired()
      }}
      projectId={projectId}
      isDisabled={isDisabled}
      data-qa="qa-gx4onxeiwz9ds44"
      hideWorkspace
      isMulti={isMulti}
      params={{ excludeBench: false }}
      isClearable
    />
  );
};

export default ActionTypeResponsible;
