import React, { useState, useCallback, useEffect } from 'react';
import { Divider, Alert } from 'antd';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { useDispatch } from 'react-redux';

import {
  TYPE_MEMBERS,
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_DATE_END,
  TYPE_TIME,
  TYPE_ADDRESS,
  TITLE,
  DESCRIPTION,
  TYPE_RELATIONS,
  TYPE_CONTACT_TO,
  TYPE_CONTACT,
  TYPE_PROJECT,
  RESPONSIBLE,
  TYPE_TAGS,
  TYPE_CO_RESPONSIBLES,
  TYPE_REQUEST,
  REQUESTS_CREATE_SLA_DEALS_FEATURE_FLAG,
  TYPE_ASSET,
  TYPE_ORDER_STATUS,
  SPRINT
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import useValidityDateFileList from 'components/common/validity-date/use-validity-date-file-list';
import Typography from 'components/common/typography';
// eslint-disable-next-line import/no-cycle
import {
  FormContactSelect,
  FormInput,
  FormInputNumber,
  FormLocationInput,
  FormProjectSelect,
  FormRelationsSelect,
  FormSprintSelect,
  FormSwitch,
  FormTagsSelect,
  LabelWithTooltip,
  validateMinLength,
  withoutBubbling
} from 'components/common/hook-form';
import { CloseIcon } from 'components/common/icon/icons';
import FormNewEditor from 'components/common/hook-form/markdown';

import { fetchOneContactLocal } from 'store/contacts';

import { useUploadingFiles } from 'hooks/common/use-file-upload/use-uploading-files';
import { useMountedState, usePrevious } from 'hooks';

import ActionTypeMembers from '../../components/actions/type-members';
import ActionTypeResponsible from '../../components/actions/type-responsible';
import ActionTypeController from '../../components/actions/type-controller';
import { getFullFilledFields } from '../utils';
import { useSlaAndChannelLogic } from './hooks/useSlaAndChannelLogic';
import { useExecutorSlaFetch } from './hooks/useExecutorSlaFetch';
import { SlaObjectSelect } from './sla-object-select/SlaObjectSelect';
import { useFormDefaultValues } from './hooks/useFormDefaultValues';
import { useDateEndChangeWithSla } from './hooks/useDateEndChangeWithSla';
import { DateFields } from './dates-fields/DateFields';
import { getAlertMessageKey, getSlaType } from './helpers/slaAlertMessages';
import { transformSubmitedValues } from './helpers/transformSubmittedValues';

import styles from './styles.module.scss';

const MainForm = ({
  onSubmit,
  current,
  currentTaskType,
  defaultValues,
  isLoading,
  isBacklog
}) => {
  const isDealsObjectSlaEnabled = useFeatureIsOn(
    REQUESTS_CREATE_SLA_DEALS_FEATURE_FLAG
  );

  const prevValues = usePrevious(defaultValues);

  const [activeFields, setActiveFields] = useState([]);
  const [visibleDateStart, setVisibleDateStart] = useState(false);
  const [contactToVisibility, setContactToVisibility] = useState(false);

  const { t } = useTranslation([
    'AddTask',
    'Requests',
    'AddTaskType',
    'Errors',
    'TaskLinks',
    'Common'
  ]);

  const methods = useFormDefaultValues(defaultValues);

  const isFromChat = !!(defaultValues?.taskInfo || {}).messageUuid;
  const isSlaRequestFormChat =
    isFromChat && !!(defaultValues?.taskInfo || {}).executorSla;

  const isCopy = !!defaultValues?.isCopy;
  const isAsset = !!defaultValues?.isAsset;
  const isDeal = !!defaultValues?.isDeal;

  const project = methods.watch(TYPE_PROJECT);
  const projectId = (project || {}).value;

  useEffect(() => {
    setVisibleDateStart(false);
    setContactToVisibility(false);

    const fullFilledFields = getFullFilledFields({
      defaultValues,
      activeFields
    });

    if (fullFilledFields.includes(TYPE_DATE_START)) setVisibleDateStart(true);

    setActiveFields(fullFilledFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTaskType]);

  // When creating a task from outside, we update the form if there have been changes in initialData
  useEffect(() => {
    if (
      prevValues &&
      Object.keys(defaultValues || {}).length &&
      !isEqual(defaultValues, prevValues)
    ) {
      methods.reset();
    }
  }, [prevValues, defaultValues]);

  const addField = type =>
    setActiveFields(
      [...activeFields, type].sort(sortByCoResponsiblesAndController)
    );

  const removeField = type => {
    setActiveFields(activeFields.filter(field => field !== type));
    methods.resetField(type);
  };

  const startDate = methods.watch(TYPE_DATE_START);
  const endDate = methods.watch(TYPE_DATE_END);
  const contact = methods.watch(TYPE_CONTACT);

  const getExecutorSla = useExecutorSlaFetch();

  const formSlaValue = methods.watch('slaObject');

  const [selectedSla, setSelectedSla] = useMountedState(null);

  const { executorSla } = useSlaAndChannelLogic({
    values: { ...defaultValues, slaObject: formSlaValue }
  });

  const { dateEnd, isDisabled } = useDateEndChangeWithSla({
    executorSla,
    isFromChat,
    isCopy,
    isAsset,
    isDeal,
    defaultValues,
    isSlaRequestFormChat
  });

  const clearEndDate = () => {
    methods.setValue(TYPE_DATE_END, null);
  };

  useEffect(() => {
    if (
      !selectedSla ||
      (selectedSla && !executorSla) ||
      (selectedSla?.item && Object.keys(selectedSla.item).length === 0)
    ) {
      clearEndDate();
    }
  }, [selectedSla]);

  useEffect(() => {
    if (formSlaValue !== selectedSla) {
      setSelectedSla(formSlaValue);
    }
  }, [formSlaValue]);

  useEffect(() => {
    if (dateEnd && dateEnd !== methods.getValues(TYPE_DATE_END)) {
      methods.setValue(TYPE_DATE_END, dateEnd);
    }
  }, [dateEnd]);

  const onSubmitHandler = async values => {
    const transformedValues = await transformSubmitedValues({
      values,
      activeFields,
      projectId,
      selectedObjectSla: values.slaObject,
      validateValidityDateStateValues,
      getExecutorSla
    });

    if (transformedValues) {
      onSubmit(transformedValues);
    }
  };

  const isUploadingFiles = useUploadingFiles(
    (methods.watch(DESCRIPTION) || {}).fileList
  );

  const isStoryPointEstimation = methods.watch('isStoryPointEstimation');

  const descriptionValues = methods.watch(DESCRIPTION);

  const { changeValidityDateStateValue, validateValidityDateStateValues } =
    useValidityDateFileList({
      fileList: descriptionValues.fileList,
      onChange: updatedFileList =>
        methods.setValue(DESCRIPTION, {
          ...descriptionValues,
          fileList: updatedFileList
        })
    });

  const actions = [
    {
      type: TYPE_ADDRESS,
      title: t('AddressHeading'),
      onClick: () => addField(TYPE_ADDRESS),
      icon: 'environment',
      allow: true,
      visibility: true,
      component: (
        <div className={styles.flexWrap}>
          <FormLocationInput
            label={t('MainAddress')}
            name="location"
            placeholder={t('EnterAddress')}
            itemProps={{
              className: styles.location
            }}
          />

          <FormInput label={t('AdditionalAddress')} name="locationExact" />
        </div>
      )
    },
    {
      type: TYPE_CONTROLLER,
      title: t('Controller'),
      allow: true,
      onClick: () => addField(TYPE_CONTROLLER),
      icon: 'user-add',
      visibility: true,
      component: (
        <ActionTypeController
          projectId={projectId}
          defaultValues={defaultValues?.controller}
        />
      )
    },
    {
      type: TYPE_TAGS,
      title: t('Tags'),
      onClick: () => addField(TYPE_TAGS),
      icon: 'tags',
      allow: true,
      visibility: true,
      component: (
        <FormTagsSelect
          name={TYPE_TAGS}
          label={t('Tags')}
          itemProps={{ style: { marginBottom: 24 } }}
        />
      )
    },
    {
      type: TYPE_DATE_START,
      title: t('StartDate'),
      onClick: () => {
        addField(TYPE_DATE_START);
        setVisibleDateStart(true);
      },
      icon: 'calendar',
      disabled: false,
      allow: true,
      visibility: true
    },
    {
      type: TYPE_PROJECT,
      title: t('Project'),
      onClick: () => addField(TYPE_PROJECT),
      icon: 'project',
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: -12
      },
      component: (
        <>
          <Typography.Text
            className={classnames(styles.subTitle, styles.block)}
          >
            {t('Project')}
          </Typography.Text>

          <div className={styles.projectFields}>
            <FormProjectSelect
              name="project"
              label={t('Project')}
              params={{
                isActive: true
              }}
              closeMenuOnSelect
              callback={() => methods.setValue(SPRINT, null)}
            />

            {project?.label?.sprintsCount > 0 && (
              <FormSprintSelect
                name="sprint"
                label={t('Sprint')}
                params={{
                  project: projectId,
                  status: ['active', 'planned'],
                  isLag: false
                }}
              />
            )}
          </div>
        </>
      )
    },
    {
      type: TYPE_RELATIONS,
      title: t('LinksHeading'),
      onClick: () => addField(TYPE_RELATIONS),
      icon: 'link',
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: -8
      },
      component: (
        <>
          <Typography.Text
            className={classnames(styles.subTitle, styles.block)}
            style={{ marginBottom: 16 }}
            data-qa="qa-tolj1gemcntd5ei"
          >
            {t('LinksHeading')}
          </Typography.Text>

          <FormRelationsSelect
            name={TYPE_RELATIONS}
            label={
              <LabelWithTooltip
                label={t('LinkType', { ns: 'TaskLinks' })}
                tooltip={t('RequestLinksDesc', { ns: 'Requests' })}
              />
            }
          />
        </>
      )
    },
    {
      type: TYPE_MEMBERS,
      title: t('Coexecutors'),
      onClick: () => addField(TYPE_MEMBERS),
      icon: 'big-team',
      allow: true,
      visibility: true,
      component: (
        <div className={styles.members} data-qa="qa-ssr3ysl0qog09qb">
          <ActionTypeMembers
            projectId={projectId}
            defaultValues={defaultValues}
          />
        </div>
      )
    },
    {
      type: TYPE_TIME,
      title: t('EstimatedTime'),
      onClick: () => addField(TYPE_TIME),
      icon: 'timer',
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: -10
      },
      component: (
        <>
          <Typography.Text
            className={classnames(styles.subTitle, styles.block)}
            data-qa="qa-5d3rzy2j6qplqgy"
          >
            {t('EstimatedTime')}
          </Typography.Text>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >
            {isStoryPointEstimation ? (
              <FormInputNumber
                label={t('Storypoints')}
                name="storyPoint"
                placeholder="000"
                maxLength={3}
              />
            ) : (
              <div className={styles.timeset} data-qa="qa-pz7tabse7kerbw0">
                <FormInputNumber
                  name="hours"
                  label={t('Hours')}
                  placeholder="000"
                  maxLength={3}
                />

                <FormInputNumber
                  name="minutes"
                  label={t('Minutes')}
                  placeholder="000"
                  maxLength={3}
                  autoFocus
                />
              </div>
            )}

            {!defaultValues?.parent && (
              <FormSwitch
                label={t('ChangeToStorypointsToggle')}
                name="isStoryPointEstimation"
                placeholder="000"
                itemProps={{ style: { margin: '27px 0 0 0', width: 'auto' } }}
              />
            )}
          </div>
        </>
      )
    }
  ];

  const renderTitle = () => (
    <div className={styles.field} data-qa="qa-bjc7ya7fwudhyna">
      <FormInput
        label={t('TaskName')}
        className={styles.title}
        name={TITLE}
        rules={{
          required: t('RequiredField', { ns: 'Errors' }),
          minLength: validateMinLength(2)
        }}
        autoFocus
        autoComplete="off"
      />
    </div>
  );

  const renderDescription = () => (
    <div
      className={classnames(styles.field, styles.description)}
      data-qa="qa-kpga5cdf9m2eks8"
    >
      <FormNewEditor
        name={DESCRIPTION}
        label={t('TaskDescriptionMobile')}
        placeholder={t('RequestDescriptionPlchldr', { ns: 'Requests' })}
        actionsDeps={{
          requestId: defaultValues?.id,
          entityType: TYPE_REQUEST
        }}
        mentionClassName={styles.mentionWrap}
        toolbarHidden
        allowAttach
        destination={{
          entityId: defaultValues?.id,
          entityType: TYPE_REQUEST
        }}
        resizeInput
        allowManageSubscribers
        hideSubscribersAction
        hideValidityDateAction
        validityDateDeps={{ changeValidityDateStateValue }}
        highlightAttachmentsBackground
      />
    </div>
  );

  const renderResponsible = useCallback(() => {
    const responsibles = methods.watch(RESPONSIBLE);

    return (
      <>
        <ActionTypeResponsible
          projectId={projectId}
          isDisabled={false}
          defaultValues={defaultValues}
        />

        {(responsibles || []).length > 1 && (
          <Alert
            type="warning"
            message={t('SeveralResponsiblesWarning')}
            style={{ fontSize: 12, marginBottom: 15 }}
          />
        )}
      </>
    );
  }, [projectId]);

  const renderDates = () => (
    <DateFields
      dateEndState={{ dateEnd, isDisabled }}
      startDate={startDate}
      endDate={endDate}
      visibleDateStart={visibleDateStart}
      onRemoveStartDate={() => {
        removeField(TYPE_DATE_START);
        setVisibleDateStart(false);
      }}
      methods={methods}
      defaultValues={defaultValues}
    />
  );

  const renderContact = () => {
    const isNotcChannelType =
      formSlaValue?.type !== TYPE_ASSET &&
      formSlaValue?.type !== TYPE_ORDER_STATUS;
    const hasValidValue =
      formSlaValue?.value || formSlaValue?.item?.value != null;
    const channelId =
      isNotcChannelType && hasValidValue
        ? formSlaValue?.value || formSlaValue?.item?.value
        : null;

    const isOrderStatusWithSla =
      formSlaValue?.type === TYPE_ORDER_STATUS && formSlaValue?.item;

    return (
      <FormContactSelect
        name={TYPE_CONTACT}
        label={`${t('Contact', { ns: 'Common' })} ${t('Optional', { ns: 'Common' })}`}
        valueText={t('EnterYourNameEmail', { ns: 'Common' })}
        reloadAfterOpen
        params={{
          channel: channelId
        }}
        isDisabled={isFromChat || isOrderStatusWithSla}
        key={channelId}
      />
    );
  };

  const renderSlaAlerts = () => {
    const slaMessageType = getSlaType({
      isSlaRequestFormChat,
      isAsset,
      isDeal,
      defaultValues,
      formSlaValue,
      executorSla,
      isCopy
    });

    if (!slaMessageType) return null;

    return (
      <Alert
        type="warning"
        message={t(getAlertMessageKey(slaMessageType), { ns: 'Requests' })}
        className={styles.slaAlert}
      />
    );
  };

  const dispatch = useDispatch();

  const setContactDataForDeals = useCallback(
    async contactId => {
      if (!contactId) return;

      const currentContact = methods.getValues(TYPE_CONTACT);

      if (currentContact && currentContact.value === contactId) return;

      const data = await dispatch(fetchOneContactLocal({ id: contactId }));
      if (data) {
        const resultValue = {
          value: contactId,
          label: data
        };

        methods.setValue(TYPE_CONTACT, resultValue);
        methods.trigger(TYPE_CONTACT);
      }
    },
    [dispatch, methods]
  );

  const renderSlaObject = () => (
    <SlaObjectSelect
      isDealsObjectSlaEnabled={isDealsObjectSlaEnabled}
      contact={contact}
      name="slaObject"
      value={formSlaValue}
      onChange={newValue => {
        methods.setValue('slaObject', newValue);

        const previousValue = formSlaValue;
        const isDealRemoved =
          previousValue?.type === TYPE_ORDER_STATUS &&
          (!newValue || newValue.type !== TYPE_ORDER_STATUS);

        if (isDealRemoved) {
          methods.setValue(TYPE_CONTACT, null);
          methods.trigger(TYPE_CONTACT);
        }
      }}
      onOrderSelect={contactId => {
        if (contactId) {
          setContactDataForDeals(contactId);
        }
      }}
      isDisabled={isFromChat || isAsset || isDeal}
    />
  );

  const filteredActions = actions
    .filter(a => !activeFields.includes(a.type))
    .filter(action => action.visibility);

  const sortByCoResponsiblesAndController = (a, b) => {
    if (a === TYPE_CONTROLLER || a === TYPE_CO_RESPONSIBLES) {
      return -1;
    }
    if (b === TYPE_CONTROLLER || b === TYPE_CO_RESPONSIBLES) {
      return 1;
    }
    return 0;
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(onSubmitHandler))
        }
        className={styles.root}
      >
        <div className={styles.fieldsWrap} data-qa="qa-qekax3a8aav153d">
          {renderSlaAlerts()}

          {renderTitle()}

          {renderDescription()}

          {renderDates()}

          {renderResponsible()}

          {renderContact()}

          {renderSlaObject()}

          {(activeFields || []).map(field => {
            const action = actions
              .filter(item => item.visibility)
              .find(item => item.type === field);

            if (field !== TYPE_DATE_START) {
              return (
                <div
                  key={field}
                  className={styles.field}
                  data-qa="qa-h5ztwdjpszrlnx0"
                >
                  {action && action.component}

                  <Button
                    type="text"
                    icon={<CloseIcon />}
                    className={styles.btnDelete}
                    style={(action || {}).deleteBtnStyle}
                    data-qa="qa-yau3zi7shuxwodz"
                    onClick={() => removeField(field)}
                  />
                </div>
              );
            }

            return undefined;
          })}

          {contactToVisibility && (
            <div className={styles.withHint} data-qa="qa-zfwkursejfcgrsi">
              <FormContactSelect
                label={t('WhoToSend')}
                valueText={t('ChooseContact')}
                name={TYPE_CONTACT_TO}
                isMulti
                closeMenuOnSelect={false}
                rules={{
                  required: contactToVisibility,
                  message: t('RequiredField', { ns: 'Errors' })
                }}
              />

              <Alert
                type="warning"
                message={t('WhoToSendWarning')}
                style={{ fontSize: 12 }}
              />
            </div>
          )}
        </div>

        <Divider className={styles.divider} data-qa="qa-sb8qczoewi8j9t7" />

        <ActionsDropdown
          actions={filteredActions}
          placement="bottomLeft"
          btnClassName={styles.actionsWrap}
          data-qa="qa-ul9pdpfvyr1qaf2"
          overlayClassName={styles.dropdownOverlay}
        >
          <Button
            type="link"
            weight="bold"
            className={styles.btnDropdown}
            data-qa="qa-s6038pwmz8o0h26"
          >
            <Icon type="plus" /> {t('ShowAllFieldsBtn')}
          </Button>
        </ActionsDropdown>

        <div className={styles.footerActions} data-qa="qa-ia26ypbtpanka41">
          <Button
            style={{ marginLeft: 'auto', minWidth: 130 }}
            htmlType="submit"
            type="primary"
            data-qa="qa-1ffnvx0iym4t05o"
            size="large"
            disabled={isLoading || isUploadingFiles}
            loading={isLoading || isUploadingFiles}
          >
            {t('CreateBtn')}{' '}
            {isUploadingFiles && ` ${t('FileLoading', { ns: 'Common' })}`}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default MainForm;
