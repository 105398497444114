import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import {
  TELEGRAM,
  WEBSITE,
  EMAIL,
  WEBSITE_CHANNEL_STEP,
  TELEGRAM_BOT_STEP,
  TELEGRAM_CHANNEL_STEP,
  ADD_CHANNEL_EVENT,
  EMAIL_SERVICE_STEP,
  EMAIL_CHANNEL_STEP,
  WEBSITE_FORM,
  WEBSITE_FORM_STEP,
  WHATSAPP,
  WHATSAPP_CHANNEL_STEP
} from 'constants/index';

import Drawer from 'components/common/drawer';
import { DEFAULT_CREATOR_FEEDBACK_CONFIG_VALUES } from 'components/contacts-view/views/integrations/components/feedback-settings';

import { getUserEmployee } from 'store/workspace';
import {
  createChannel,
  emailAuthentication,
  notifyChannel,
  operatorsChannel,
  sourceExistence
} from 'store/channels';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';
import useAmplitude from 'hooks/amplitude/use-amplitude';
import { useUpserviceWidgetContext } from 'providers';

import SelectTypeStep from './steps/select-type';
import { getFirstStep, getPreviousStep } from './steps/utils';
import TelegramStep from './steps/telegram';
import {
  TelegramCreationSuccessedModal,
  WebsiteCreationSuccessedModal,
  WebsiteFormCreationSuccessedModal,
  WhatsAppCreationSuccessedModal
} from '../../modals/creation-successed';
import WebsiteStep from './steps/website';
import EmailStep from './steps/email';
import WebsiteFormStep from './steps/website-form';
import WebsiteFormPreview from './steps/website-form/preview';
import { transformScheduleConfigValues } from '../../components/operator-hours/utils';
import { transformSlaConfigValues } from '../../components/sla-settings/utils';
import WhatsAppStep from './steps/whatsapp';
import WhatsAppAuthModal from '../../modals/whatsapp-auth';
import useServiceUnavailable from '../../modals/service-unavailable/use-service-unavailable';

const ChannelsCreatorDrawer = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();
  const amplitude = useAmplitude();

  const { checkServiceAvailability } = useServiceUnavailable();

  const [currentType, setCurrentType] = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const [defaultValues, setDefaultValues] = useState({});
  const [websiteFormValues, setWebsiteFormValues] = useState({});

  const [successedModalData, setSuccessedModalData] = useState(undefined);
  const [visibleWhatsAppAuthModal, setVisibleWhatsAppAuthModal] =
    useState(undefined);

  const [isLoading, setIsLoading] = useState(false);

  const userEmployee = useSelector(getUserEmployee);

  const { removeWidget, recreateWidget } = useUpserviceWidgetContext();

  const { t } = useTranslation([
    'ConnectChannel',
    'ConnectWidget',
    'ConnectTelegram',
    'ConnectEmail',
    'ConnectForm',
    'ConnectWhatsApp',
    'Errors'
  ]);

  const creator = { value: userEmployee.id, label: userEmployee };

  const previousStep = getPreviousStep(currentStep, currentType);

  const handleClose = useCallback(() => {
    if (currentType === WEBSITE) {
      recreateWidget();
    }

    onClose();
  }, [currentType, onClose, recreateWidget]);

  const selectType = useCallback(
    type => {
      if (type === WHATSAPP && !checkServiceAvailability()) {
        return;
      }

      setCurrentType(type);
      setCurrentStep(getFirstStep(type));
    },
    [checkServiceAvailability]
  );

  const onBack = useCallback(() => {
    if (!previousStep) {
      setCurrentType(null);
    }

    setCurrentStep(previousStep);
  }, [previousStep]);

  const changeOperators = useCallback(
    async (id, operators) => dispatch(operatorsChannel({ id, operators })),
    [dispatch]
  );

  const onCreateChannel = useCallback(
    async ({ operators, ...values }) => {
      const channel = await dispatch(createChannel({ channel: values }));

      if ((operators || []).length) {
        const { operators: operatorList } = await changeOperators(
          channel.id,
          operators
        );

        const updatedChannel = { ...channel, operators: operatorList };

        return updatedChannel;
      }

      return channel;
    },
    [changeOperators, dispatch]
  );

  const onSubmitWebsite = useCallback(
    async values => {
      try {
        setIsLoading(true);

        const channel = await onCreateChannel(values);
        amplitude.logEvent({ event: ADD_CHANNEL_EVENT });

        if (data.callback) {
          data.callback(channel);
        } else {
          setSuccessedModalData(channel);
        }

        handleClose();
      } finally {
        setIsLoading(false);
      }
    },
    [amplitude, data, handleClose, onCreateChannel]
  );

  const onSubmitTelegram = useCallback(
    async values => {
      if (currentStep === TELEGRAM_BOT_STEP) {
        try {
          setIsLoading(true);

          const { firstName, username } = await dispatch(
            sourceExistence({ source: values.token, kind: TELEGRAM })
          );

          // in Telegram "username" is displayed on the front in the "source" field, but is displayed on the back in the "config.name" field
          // "token" is not displayed anywhere, it is sent to the back in the "source" field
          setDefaultValues({
            token: values.token,
            name: firstName,
            source: username,
            responsible: creator,
            operators: [creator],
            isAskFeedback: true,
            notify: true,
            scheduleConfig: transformScheduleConfigValues(),
            slaConfig: transformSlaConfigValues(),
            feedbackConfig: DEFAULT_CREATOR_FEEDBACK_CONFIG_VALUES
          });
          setCurrentStep(TELEGRAM_CHANNEL_STEP);
        } finally {
          setIsLoading(false);
        }
      }

      if (currentStep === TELEGRAM_CHANNEL_STEP) {
        try {
          setIsLoading(true);

          const channel = await onCreateChannel(values);

          if (!values.notify) {
            await dispatch(
              notifyChannel({ id: channel.id, value: values.notify })
            );
          }

          amplitude.logEvent({ event: ADD_CHANNEL_EVENT });

          if (data.callback) {
            data.callback(channel);
          } else {
            setSuccessedModalData({ ...channel, notify: values.notify });
          }

          showNoticeMessage({ number: NOTICE_NUMBER.channelConnected });
          handleClose();
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      amplitude,
      creator,
      currentStep,
      data,
      dispatch,
      handleClose,
      onCreateChannel
    ]
  );

  const onSubmitEmail = useCallback(
    async values => {
      if (currentStep === EMAIL_SERVICE_STEP) {
        try {
          setIsLoading(true);

          await dispatch(
            sourceExistence({ source: values.email, kind: EMAIL })
          );

          await dispatch(emailAuthentication({ data: values }));

          setDefaultValues({
            ...values,
            service: values.service.value,
            source: values.email,
            responsible: creator,
            operators: [creator],
            notify: true,
            scheduleConfig: transformScheduleConfigValues(),
            slaConfig: transformSlaConfigValues(),
            feedbackConfig: DEFAULT_CREATOR_FEEDBACK_CONFIG_VALUES
          });

          setCurrentStep(EMAIL_CHANNEL_STEP);
        } catch ({ response }) {
          if (response.status === 428) {
            notification.warn({
              message: t('SomethingWentWrong', { ns: 'Errors' }),
              description: (
                <div>
                  {t('ImapErrorConnectedDesc', { ns: 'ConnectEmail' })}{' '}
                  <a
                    href="https://help.upservice.com/articles/98499-%D0%BA%D0%B0%D0%BA-%D0%BF%D0%BE%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D1%8C-%D0%B2%D0%B0%D1%88%D1%83-%D0%BF%D0%BE%D1%87%D1%82%D1%83-%D0%BA-upservice"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('ImapErrorConnectedLink', { ns: 'ConnectEmail' })}
                  </a>
                </div>
              )
            });
          }
        } finally {
          setIsLoading(false);
        }
      }

      if (currentStep === EMAIL_CHANNEL_STEP) {
        try {
          setIsLoading(true);

          const channel = await onCreateChannel(values);

          if (!values.notify) {
            await dispatch(
              notifyChannel({ id: channel.id, value: values.notify })
            );
          }

          amplitude.logEvent({ event: ADD_CHANNEL_EVENT });

          if (data.callback) {
            data.callback(channel);
          }

          showNoticeMessage({ number: NOTICE_NUMBER.channelConnected });
          handleClose();
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      amplitude,
      creator,
      currentStep,
      data,
      dispatch,
      handleClose,
      onCreateChannel,
      t
    ]
  );

  const onSubmitWhatsApp = useCallback(
    async values => {
      try {
        setIsLoading(true);

        const channel = await onCreateChannel(values);
        amplitude.logEvent({ event: ADD_CHANNEL_EVENT });

        setVisibleWhatsAppAuthModal(channel);
        handleClose();
      } finally {
        setIsLoading(false);
      }
    },
    [amplitude, handleClose, onCreateChannel]
  );

  const componentData = useMemo(() => {
    if (currentType === WEBSITE) {
      return {
        title: (
          <>
            {data.hideBackButton && !previousStep ? null : (
              <Drawer.Back onClick={onBack} />
            )}
            <Drawer.Title>
              {t('ConnectChannelHeading', { ns: 'ConnectWidget' })}
            </Drawer.Title>
          </>
        ),
        component: (
          <WebsiteStep
            creator={creator}
            channel={data.channel}
            currentStep={currentStep}
            isLoading={isLoading}
            onSubmit={onSubmitWebsite}
          />
        )
      };
    }

    if (currentType === TELEGRAM) {
      return {
        title: (
          <>
            {data.hideBackButton && !previousStep ? null : (
              <Drawer.Back onClick={onBack} />
            )}
            <Drawer.Title>
              {t('ConnectTelegramHeading', { ns: 'ConnectTelegram' })}
            </Drawer.Title>
          </>
        ),
        component: (
          <TelegramStep
            currentStep={currentStep}
            defaultValues={defaultValues}
            isLoading={isLoading}
            onSubmit={onSubmitTelegram}
          />
        )
      };
    }

    if (currentType === EMAIL) {
      return {
        title: (
          <>
            {data.hideBackButton && !previousStep ? null : (
              <Drawer.Back onClick={onBack} />
            )}
            <Drawer.Title>
              {t('ConnectEmailHeading', { ns: 'ConnectEmail' })}
            </Drawer.Title>
          </>
        ),
        component: (
          <EmailStep
            currentStep={currentStep}
            defaultValues={defaultValues}
            isLoading={isLoading}
            onSubmit={onSubmitEmail}
          />
        )
      };
    }

    if (currentType === WEBSITE_FORM) {
      return {
        title: (
          <>
            {data.hideBackButton && !previousStep ? null : (
              <Drawer.Back onClick={onBack} />
            )}
            <Drawer.Title>
              {t('ConnectFormChannelHeading', { ns: 'ConnectForm' })}
            </Drawer.Title>
          </>
        ),
        component: (
          <>
            <WebsiteFormStep
              creator={creator}
              setValues={setWebsiteFormValues}
              channel={data.channel}
              currentStep={currentStep}
              isLoading={isLoading}
              onSubmit={onSubmitWebsite}
            />
          </>
        )
      };
    }

    if (currentType === WHATSAPP) {
      return {
        title: (
          <>
            {data.hideBackButton && !previousStep ? null : (
              <Drawer.Back onClick={onBack} />
            )}
            <Drawer.Title>
              {t('ConnectWhatsAppHeading', { ns: 'ConnectWhatsApp' })}
            </Drawer.Title>
          </>
        ),
        component: (
          <>
            <WhatsAppStep
              defaultValues={defaultValues}
              creator={creator}
              currentStep={currentStep}
              isLoading={isLoading}
              onSubmit={onSubmitWhatsApp}
            />
          </>
        )
      };
    }

    return {
      title: <Drawer.Title>{t('ConnectChannelHeading')}</Drawer.Title>,
      component: <SelectTypeStep onClick={selectType} />
    };
  }, [
    creator,
    currentStep,
    currentType,
    data.channel,
    data.hideBackButton,
    defaultValues,
    isLoading,
    onBack,
    onSubmitEmail,
    onSubmitTelegram,
    onSubmitWebsite,
    onSubmitWhatsApp,
    previousStep,
    selectType,
    t
  ]);

  useEffect(() => {
    if (!visible) {
      setDefaultValues(prev => (Object.keys(prev).length ? {} : prev));
      setCurrentStep(null);
      setCurrentType(null);
    }

    if (visible && typeof data === 'object') {
      setCurrentStep(data.step);
      setCurrentType(data.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, visible]);

  useEffect(() => {
    if (visible && currentType === WEBSITE) {
      removeWidget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentType, visible]);

  return (
    <>
      <Drawer
        title={componentData.title}
        width={620}
        destroyOnClose
        maskClosable={false}
        bodyStyle={{ padding: '24px 24px 0' }}
        open={visible}
        onClose={handleClose}
        mask={currentStep !== WEBSITE_FORM_STEP}
      >
        {componentData.component}
      </Drawer>

      {currentStep === WEBSITE_FORM_STEP && (
        <WebsiteFormPreview values={websiteFormValues} />
      )}

      <TelegramCreationSuccessedModal
        data={successedModalData}
        visible={
          successedModalData !== undefined &&
          successedModalData.kind === TELEGRAM
        }
        onClose={() => setSuccessedModalData(undefined)}
      />

      <WhatsAppCreationSuccessedModal
        data={successedModalData}
        visible={
          successedModalData !== undefined &&
          successedModalData.kind === WHATSAPP
        }
        onClose={() => setSuccessedModalData(undefined)}
      />

      <WebsiteCreationSuccessedModal
        data={successedModalData}
        visible={
          successedModalData !== undefined &&
          successedModalData.kind === WEBSITE
        }
        onClose={() => setSuccessedModalData(undefined)}
      />

      <WebsiteFormCreationSuccessedModal
        data={successedModalData}
        onClose={() => setSuccessedModalData(undefined)}
        visible={
          successedModalData !== undefined &&
          successedModalData.kind === WEBSITE_FORM
        }
      />

      <WhatsAppAuthModal
        visible={visibleWhatsAppAuthModal}
        onClose={() => {
          setVisibleWhatsAppAuthModal(false);
        }}
        setSuccessedModalData={setSuccessedModalData}
      />
    </>
  );
};

ChannelsCreatorDrawer.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.oneOf([TELEGRAM, WEBSITE, WHATSAPP]),
    step: PropTypes.oneOf([
      TELEGRAM_BOT_STEP,
      TELEGRAM_CHANNEL_STEP,
      WEBSITE_CHANNEL_STEP,
      WEBSITE_FORM_STEP,
      WHATSAPP_CHANNEL_STEP
    ]),
    hideBackButton: PropTypes.bool,
    callback: PropTypes.func
  }),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

ChannelsCreatorDrawer.defaultProps = {
  data: {
    hideBackButton: false
  }
};

export default ChannelsCreatorDrawer;
