import {
  ACCESS_TYPE_PUBLIC,
  DESCRIPTION,
  SCHEDULER,
  TYPE_AGREEMENT_TASK,
  TYPE_COMPLETE_REQUIRE,
  TYPE_CONTACT,
  TYPE_DATE_START,
  TYPE_MEETING_CONTACTS,
  TYPE_RELATIONS,
  TYPE_TIME
} from 'constants/index';

import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';
import mapValue from 'utils/map-value';

export const transformSubmittedValues = ({
  values,
  activeFields,
  currentTaskType,
  isTopTask,
  defaultValues
}) => {
  const getRelations = () => {
    let results = [];

    if (activeFields.includes(TYPE_RELATIONS)) {
      results = [...(values.relations || [])];
    }

    if (activeFields.includes(TYPE_MEETING_CONTACTS)) {
      results = [
        ...results,
        ...transformValueToRelation(values.meetingContacts || [], TYPE_CONTACT)
      ];
    }

    return results;
  };

  return {
    // ...values,
    hours: activeFields.includes(TYPE_TIME) ? values.hours : null,
    minutes: activeFields.includes(TYPE_TIME) ? values.minutes : null,
    approvingManager:
      currentTaskType === TYPE_AGREEMENT_TASK ? values.approvingManager : null,
    relations: getRelations(),
    project: isTopTask ? (values.project || {}).value || null : null,
    sprint: isTopTask ? (values.sprint || {}).value || null : null,
    storyPoint: +values.storyPoint || undefined,
    ...values[DESCRIPTION],
    [DESCRIPTION]: (values[DESCRIPTION] || {})[DESCRIPTION] && [
      {
        text: values[DESCRIPTION].description
      },
      ...(values[DESCRIPTION].links || [])
    ],
    ...(values[SCHEDULER]
      ? {
          schedulerConfig: {
            freqInterval: values.freqInterval,
            weekdays: values.weekdays,
            freqType: values.freqType
          }
        }
      : {}),
    dateStart: activeFields.includes(TYPE_DATE_START) ? values.dateStart : null,
    dateEnd: values.dateEnd,
    accessType: values.accessType
      ? mapValue(values.accessType)
      : defaultValues?.parentAccessType || ACCESS_TYPE_PUBLIC,
    title: values.title,
    responsible: values.responsible,
    members: values.members,
    meetingContacts: values.meetingContacts,
    location: values.location || '',
    locationExact: values.locationExact || '',
    kind: currentTaskType,
    completeRequire: activeFields.includes(TYPE_COMPLETE_REQUIRE)
      ? values.completeRequire
      : null,
    [SCHEDULER]: values[SCHEDULER],
    isFromEditor: values.isFromEditor,
    fileList: values?.[DESCRIPTION]?.fileList || [],
    estimation: values?.estimation,
    coResponsibles: values?.coResponsibles,
    tags: values?.tags,
    controller: values?.controller,
    isStoryPointEstimation: values?.isStoryPointEstimation,
    isScheduler: values?.isScheduler,
    agreementSteps: values?.agreementSteps,
    updateTemplate: values?.updateTemplate,
    saveAsTemplate: values?.saveAsTemplate,
    sendLogsToContact: values?.sendLogsToContact,
    discussion: values?.discussion,
    taskInfo: values?.taskInfo,
    channelChat: values?.channelChat,
    template: values?.template,
    attachmentVersion: values?.attachmentVersion,
    parent: values?.parent,
    workLogTotal: values?.workLogTotal,
    workLog: values?.workLog,
    estimationTotal: values?.estimationTotal,
    author: values?.author
  };
};
