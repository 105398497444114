import { useRef } from 'react';

import useMediaViewer from 'components/common/media-viewer-provider/use-media-viewer';

import { getIsMediaFile, getIsEditableFile } from './types';

export const useFileView = () => {
  const newWindowRef = useRef(null);

  const { onOpen, onClose } = useMediaViewer();

  const onOpenInNewTab = url => {
    if (newWindowRef.current) {
      newWindowRef.current.location.replace(url);
      newWindowRef.current.focus();
      const ref = newWindowRef.current;
      newWindowRef.current = null;

      return ref;
    }

    return window.open(url);
  };

  const closePrepared = () => {
    if (newWindowRef.current) {
      newWindowRef.current.close();
      newWindowRef.current = null;
    }
  };

  const prepare = () => {
    // браузеры блочат окна открытие которых не инициировано пользователем.
    // поэтому открываем окно сразу по клику,
    // а подгружаем нужный контент после его получения.
    newWindowRef.current = window.open('/loading.html');
  };

  const view = ({ mimeType, url, title, name, type }) => {
    if (!url) return null;

    if (getIsMediaFile({ mimeType, type })) {
      closePrepared();
      return onOpen([{ url, name: title || name, mimeType: mimeType || type }]);
    }

    if (getIsEditableFile({ mimeType, type })) {
      return onOpenInNewTab(url);
    }

    return onOpenInNewTab(url);
  };

  const closePreview = () => {
    onClose();
  };

  return {
    prepare,
    closePrepared,
    view,
    closePreview
  };
};
