import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { TYPE_CONTROLLER } from 'constants/index';

import Icon from 'components/common/icon';
// eslint-disable-next-line import/no-cycle
import {
  FormProjectParticipantsSelect,
  FormEmployeeSelect
} from 'components/common/hook-form';
import Typography from 'components/common/typography';
import Tooltip from 'components/common/tooltip';

const ActionTypeController = ({ projectId, defaultValues }) => {
  const { t } = useTranslation(['AddTask', 'Requests']);
  const SelectEmployee = projectId
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;

  const label = (
    <>
      <Typography.Text
        size="small"
        style={{ color: 'inherit', marginRight: 5 }}
      >
        {t('Controller')}
      </Typography.Text>

      <Tooltip title={t('ControllerTip', { ns: 'Requests' })}>
        <Icon
          type="question-circle"
          size={12}
          style={{ verticalAlign: 'unset' }}
        />
      </Tooltip>
    </>
  );

  return (
    <SelectEmployee
      label={label}
      name={TYPE_CONTROLLER}
      projectId={projectId}
      hideWorkspace
      isMulti={false}
      allowSetYourself={false}
    />
  );
};

ActionTypeController.propTypes = {
  projectId: PropTypes.number,
  defaultValues: PropTypes.object
};

ActionTypeController.defaultProps = {
  projectId: undefined,
  defaultValues: {}
};

export default ActionTypeController;
