import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DATE_PICKER_TIME_FORMAT, DATE_PICKER_FORMAT } from 'constants/index';

import DatePicker from 'components/common/date-picker';

import FormItem from '../form-item';

export const FormDatePicker = ({
  rules,
  name,
  label,
  itemProps,
  showTimeSelect,
  defaultToMidnight = false,
  ...props
}) => {
  const { control, formState } = useFormContext();

  const getDateWithDefaultTime = e => {
    if (defaultToMidnight) return moment(e).toDate();

    const date = moment(e).toDate().setHours(23, 45);

    return moment(date).toDate();
  };

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => {
          const { value, onChange, ...renderProps } = field;

          return (
            <DatePicker
              showTimeSelect={showTimeSelect}
              timeIntervals={15}
              selected={value}
              dateFormat={
                showTimeSelect ? DATE_PICKER_TIME_FORMAT : DATE_PICKER_FORMAT
              }
              onChange={e =>
                onChange(
                  !value && !moment(e).toDate().getHours() && showTimeSelect
                    ? getDateWithDefaultTime(e)
                    : e
                )
              }
              {...renderProps}
              {...props}
            />
          );
        }}
      />
    </FormItem>
  );
};

FormDatePicker.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object,
  showTimeSelect: PropTypes.bool
};

FormDatePicker.defaultProps = {
  rules: {},
  itemProps: {},
  showTimeSelect: true
};

export default FormDatePicker;
