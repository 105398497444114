import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import FunnelTypeSelect from 'components/common/controls/custom-select/funnel-type-select';

import FormItem from '../form-item';

export const FormFunnelTypeSelect = ({
  rules,
  name,
  label,
  itemProps,
  callback,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => {
          const { onChange, value } = field;

          const handleChange = newValue => {
            onChange(newValue);
            callback(newValue);
          };

          return (
            <FunnelTypeSelect
              onChange={handleChange}
              value={value}
              {...props}
            />
          );
        }}
      />
    </FormItem>
  );
};

FormFunnelTypeSelect.propTypes = {
  rules: PropTypes.any,
  itemProps: PropTypes.object,
  isLinkToElement: PropTypes.bool,
  callback: PropTypes.func
};

FormFunnelTypeSelect.defaultProps = {
  rules: {},
  itemProps: {},
  isLinkToElement: false,
  callback: () => {}
};

export default FormFunnelTypeSelect;
