import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GoogleDriveNotConnectedIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
// eslint-disable-next-line import/no-cycle
import { FormRelationsSelect } from 'components/common/hook-form';
import Button from 'components/common/button';
import { FormUpload } from 'components/common/hook-form/upload';

import { useWorkspaceGoogleDriveSupport } from 'hooks/workspace/useWorkspaceGoogleDriveSupport';

import styles from './upload-form.module.scss';

const { Paragraph, Title } = Typography;

const RELATION_FIELD_NAME = 'relation';

const FILE_LIST_FIELD_NAMES = {
  fileList: 'fileList',
  fileListOwn: 'fileListOwn'
};

const VALIDITY_DATES_FIELD_NAMES = {
  validityDates: 'validityDates',
  validityDatesOwn: 'validityDatesOwn'
};

const SUBSCRIBERS_FOR_ATTACHMENTS_FIELD_NAMES = {
  subscribersForAttachments: 'subscribersForAttachments',
  subscribersForAttachmentsOwn: 'subscribersForAttachmentsOwn'
};

export const UploadForm = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation(['UploadFile', 'Errors']);

  const {
    isWorkspaceOwnGoogleDriveIntegrationEnabled,
    isOwnAndCommonGoogleDriveIntegrationEnabled
  } = useWorkspaceGoogleDriveSupport();

  const methods = useForm({
    defaultValues: {
      [RELATION_FIELD_NAME]: [],
      [SUBSCRIBERS_FOR_ATTACHMENTS_FIELD_NAMES.subscribersForAttachments]: [],
      [SUBSCRIBERS_FOR_ATTACHMENTS_FIELD_NAMES.subscribersForAttachmentsOwn]:
        [],
      [VALIDITY_DATES_FIELD_NAMES.validityDates]: [],
      [VALIDITY_DATES_FIELD_NAMES.validityDatesOwn]: [],
      [FILE_LIST_FIELD_NAMES.fileList]: [],
      [FILE_LIST_FIELD_NAMES.fileListOwn]: []
    }
  });

  const fileListWatch = methods.watch(FILE_LIST_FIELD_NAMES.fileList);
  const fileListOwnWatch = methods.watch(FILE_LIST_FIELD_NAMES.fileListOwn);
  const relationsWatch = methods.watch(RELATION_FIELD_NAME);

  const isSubmitDisabled =
    !relationsWatch.length || ![...fileListWatch, ...fileListOwnWatch].length;

  const isBothFileListValidationRequired =
    !isOwnAndCommonGoogleDriveIntegrationEnabled ||
    (isOwnAndCommonGoogleDriveIntegrationEnabled &&
      !fileListOwnWatch.length &&
      !fileListWatch.length);

  const {
    fields: subscribersForAttachmentsFields,
    append: appendSubAttachment,
    remove: removeSubAttachment
  } = useFieldArray({
    control: methods.control,
    name: SUBSCRIBERS_FOR_ATTACHMENTS_FIELD_NAMES.subscribersForAttachments
  });

  const {
    fields: subscribersForAttachmentsFieldsOwn,
    append: appendSubAttachmentOwn,
    remove: removeSubAttachmentOwn
  } = useFieldArray({
    control: methods.control,
    name: SUBSCRIBERS_FOR_ATTACHMENTS_FIELD_NAMES.subscribersForAttachmentsOwn
  });

  const {
    fields: validityDateFields,
    append: appendValidityDateAttachment,
    remove: removeValidityDateAttachment
  } = useFieldArray({
    control: methods.control,
    name: VALIDITY_DATES_FIELD_NAMES.validityDates
  });

  const {
    fields: validityDateFieldsOwn,
    append: appendValidityDateAttachmentOwn,
    remove: removeValidityDateAttachmentOwn
  } = useFieldArray({
    control: methods.control,
    name: VALIDITY_DATES_FIELD_NAMES.validityDatesOwn
  });

  const handleSubmit = ({
    relation,
    fileList,
    fileListOwn,
    subscribersForAttachments,
    subscribersForAttachmentsOwn,
    validityDates,
    validityDatesOwn
  }) => {
    const formattedFileList = [...fileList, ...fileListOwn].map(f => ({
      id: f.response.id,
      mimeType: f.type,
      title: f.name
    }));
    const formattedSubscribersForAttachments = [
      ...subscribersForAttachments,
      ...subscribersForAttachmentsOwn
    ].map(({ parentName, ...rest }) => ({
      ...rest
    }));
    const formattedValidityDates = [...validityDates, ...validityDatesOwn].map(
      ({ parentName, ...rest }) => ({
        ...rest
      })
    );

    return onSubmit({
      relation,
      fileList: formattedFileList,
      subscribersForAttachments: formattedSubscribersForAttachments,
      validityDates: formattedValidityDates
    });
  };

  return (
    <div className={styles.uploadForm}>
      <Paragraph>{t('UploadFileDesc')}</Paragraph>

      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <FormRelationsSelect
            name={RELATION_FIELD_NAME}
            label={t('Link')}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            allowToSelectOne
            itemProps={{
              className: styles.relations
            }}
          />

          {isOwnAndCommonGoogleDriveIntegrationEnabled && (
            <div
              className={styles.divider}
              style={{ marginTop: 22, marginBottom: 9 }}
            />
          )}

          {(isOwnAndCommonGoogleDriveIntegrationEnabled ||
            !isWorkspaceOwnGoogleDriveIntegrationEnabled) && (
            <FormUpload
              hasWorkspaceGoogleDriveSupport={false}
              name={FILE_LIST_FIELD_NAMES.fileList}
              rules={{
                required:
                  isBothFileListValidationRequired &&
                  t('RequiredField', { ns: 'Errors' })
              }}
              multiple
              itemProps={{
                className: styles.upload
              }}
              description={
                isOwnAndCommonGoogleDriveIntegrationEnabled ? (
                  <div
                    style={{
                      margin: '0 auto',
                      maxWidth: 228
                    }}
                  >
                    <Title
                      level={4}
                      style={{
                        fontSize: 16,
                        lineHeight: 1.5,
                        marginBottom: 8
                      }}
                    >
                      {t('CompanyName', { ns: 'Common' })}
                    </Title>
                    <Paragraph>
                      {t('DragnDropDesc', { ns: 'UploadFile' })}
                    </Paragraph>
                  </div>
                ) : undefined
              }
              attachmentProps={{
                sub: {
                  fields: subscribersForAttachmentsFields,
                  append: appendSubAttachment,
                  remove: removeSubAttachment,
                  parentName:
                    SUBSCRIBERS_FOR_ATTACHMENTS_FIELD_NAMES.subscribersForAttachments
                },
                allowManageSubscribers: true,
                validityDates: {
                  fields: validityDateFields,
                  append: appendValidityDateAttachment,
                  remove: removeValidityDateAttachment,
                  parentName: VALIDITY_DATES_FIELD_NAMES.validityDates
                },
                hideValidityDateAction: true
              }}
            />
          )}

          {isOwnAndCommonGoogleDriveIntegrationEnabled && (
            <div
              className={styles.divider}
              style={{ marginTop: -7, marginBottom: 9 }}
            />
          )}

          {(isOwnAndCommonGoogleDriveIntegrationEnabled ||
            isWorkspaceOwnGoogleDriveIntegrationEnabled) && (
            <FormUpload
              hasWorkspaceGoogleDriveSupport
              name={FILE_LIST_FIELD_NAMES.fileListOwn}
              rules={{
                required:
                  isBothFileListValidationRequired &&
                  t('RequiredField', { ns: 'Errors' })
              }}
              multiple
              itemProps={{
                className: styles.upload
              }}
              icon={GoogleDriveNotConnectedIcon}
              description={
                isOwnAndCommonGoogleDriveIntegrationEnabled ? (
                  <div
                    style={{
                      margin: '0 auto',
                      maxWidth: 228
                    }}
                  >
                    <Title
                      level={4}
                      style={{
                        fontSize: 16,
                        lineHeight: 1.5,
                        marginBottom: 8
                      }}
                    >
                      {t('GoogleDrive', { ns: 'Common' })}
                    </Title>
                    <Paragraph>
                      {t('DragnDropDesc', { ns: 'UploadFile' })}
                    </Paragraph>
                  </div>
                ) : undefined
              }
              attachmentProps={{
                sub: {
                  fields: subscribersForAttachmentsFieldsOwn,
                  append: appendSubAttachmentOwn,
                  remove: removeSubAttachmentOwn,
                  parentName:
                    SUBSCRIBERS_FOR_ATTACHMENTS_FIELD_NAMES.subscribersForAttachmentsOwn
                },
                allowManageSubscribers: true,
                validityDates: {
                  fields: validityDateFieldsOwn,
                  append: appendValidityDateAttachmentOwn,
                  remove: removeValidityDateAttachmentOwn,
                  parentName: VALIDITY_DATES_FIELD_NAMES.validityDatesOwn
                },
                hideValidityDateAction: true
              }}
            />
          )}

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            width="expanded"
            loading={isLoading}
            className={styles.submit}
            disabled={isSubmitDisabled}
          >
            {t('AddBtn')}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

UploadForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

UploadForm.defaultProps = {
  isLoading: false
};
