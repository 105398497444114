import React, { useState, useEffect } from 'react';
import { Divider, Alert } from 'antd';
import moment from 'moment';
import classnames from 'classnames';
import { isEqual, union, uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';

import {
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_DATE_END,
  TYPE_TIME,
  TITLE,
  DATE_TIME_FORMAT_FM,
  TYPE_TASK,
  STATUS_BACKLOG,
  DESCRIPTION,
  DATE_PICKER_TIME_FORMAT,
  TYPE_ADDRESS,
  TYPE_MEETING,
  TYPE_CONTACT,
  TYPE_PROJECT,
  TYPE_AGREEMENT,
  SCHEDULER,
  TYPE_ACQUAINTANCE,
  TYPE_CO_RESPONSIBLES,
  TYPE_APPROVING_MANAGER,
  TYPE_AGREEMENT_TASK,
  TYPE_COMPLETE_REQUIRE,
  TYPE_RELATIONS,
  TYPE_ASSET,
  TYPE_MEETING_CONTACTS,
  STATUS_DONE,
  STATUS_CANCELLED
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
// eslint-disable-next-line import/no-cycle
import {
  FormCheckbox,
  FormCompleteRequireTaskSelect,
  FormContactSelect,
  FormDatePicker,
  FormEmployeeSelect,
  FormInput,
  FormLocationInput,
  FormProjectParticipantsSelect,
  FormProjectSelect,
  FormRelationsSelect,
  FormSprintSelect,
  FormSwitch,
  LabelWithTooltip,
  validateRequired,
  validateMinLength,
  validateMaxLength,
  validateIsNumber,
  withoutBubbling
} from 'components/common/hook-form';
import useMeetingCalendarContext from 'components/tasks-view/components/meeting-calendar/use-meeting-calendar-context';
import { CloseIcon } from 'components/common/icon/icons';
import useValidityDateFileList from 'components/common/validity-date/use-validity-date-file-list';
import FormNewEditor from 'components/common/hook-form/markdown';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import useMinMaxTime from 'hooks/common/use-min-max-time';
import { usePrevious } from 'hooks/common';
import alphabeticallySort from 'utils/alphabetically-sort';
import { useUploadingFiles } from 'hooks/common/use-file-upload/use-uploading-files';
import { getFullName } from 'utils/get-fio';

import {
  getFieldsByTypes,
  getFullFilledFields,
  isAllowedToDelete
} from '../creator/utils';
import TaskTypeSelect from '../../../components/task-type-select';
import ActionTypeController from '../components/actions/type-controller';
import ActionTypeResponsible from '../components/actions/type-responsible';
import ActionTypeCoResponsibles from '../components/actions/type-co-responsibles';
import AgreementSteps from '../creator/main-form/agreement-steps';
import IntervalSelect from '../creator/main-form/interval-select';
import { useFormDefaultValues } from './hooks/useFormDefaultValues';
import { useDateValidationRules } from './hooks/useDateValidationRules';
import { transformSubmittedValues } from './helpers/transformSubmittedValues';

import styles from './editor.module.scss';

const { Text } = Typography;

const BaseForm = ({
  onSubmit,
  defaultValues = {},
  isSubmitting = false,
  isTemplate,
  meetingCalendar
}) => {
  const { t } = useTranslation([
    'AddTask',
    'Errors',
    'TaskLinks',
    'EditTaskTemplate'
  ]);

  const amplitude = useAmplitude();

  const [activeFields, setActiveFields] = useState([]);
  const [visibleDateStart, setVisibleDateStart] = useState(false);
  const [visibleEstimate, setVisibleEstimate] = useState(false);
  const [currentTaskType, setCurrentTaskType] = useState(
    (defaultValues || {}).kind
  );

  const prevValues = usePrevious(defaultValues);
  const prevTaskType = usePrevious(currentTaskType);

  const { goToDate, selectedDates, selectDatesToCalendar, setResources } =
    useMeetingCalendarContext();

  const isTypeTask = (currentTaskType || '').includes(TYPE_TASK);
  const isTypeMeeting = currentTaskType === TYPE_MEETING;
  const isTypeAgreement = currentTaskType === TYPE_AGREEMENT;
  const isTypeAcquaintence = currentTaskType === TYPE_ACQUAINTANCE;
  const isTopTask = !(defaultValues || {}).parent;
  const isSlaTask = !!Object.values(defaultValues.taskInfo || {}).length;
  const isBacklog = defaultValues && defaultValues.status === STATUS_BACKLOG;
  const isStatusCancelled =
    defaultValues && defaultValues.status === STATUS_CANCELLED;
  const isStatusDone = defaultValues && defaultValues.status === STATUS_DONE;
  const allowEditProject = isTopTask;
  const allowEditSprint =
    allowEditProject && isTypeTask && !isStatusCancelled && !isStatusDone;
  const hideResponsibleField =
    isTypeAgreement || isTypeAcquaintence || isTypeMeeting;

  const methods = useFormDefaultValues({
    defaultValues,
    isTypeMeeting,
    isTemplate
  });

  const descriptionWatch = methods.watch(DESCRIPTION);
  const isFilesLoading = useUploadingFiles((descriptionWatch || {}).fileList);
  const isActiveScheduler = methods.watch('isActiveScheduler');
  const coResponsibles = methods.watch(TYPE_CO_RESPONSIBLES);

  const getDateFieldValue = key => {
    const value = methods.getValues(key);

    return moment(value).isValid() ? value : undefined;
  };

  const startDate = getDateFieldValue(TYPE_DATE_START);
  const endDate = getDateFieldValue(TYPE_DATE_END);
  const project = methods.watch('project');
  const projectId = (project || {}).value;
  const isStoryPointEstimation =
    methods.watch('isStoryPointEstimation') === undefined
      ? defaultValues.isStoryPointEstimation
      : methods.watch('isStoryPointEstimation');
  const isPeriodic = methods.watch(SCHEDULER);

  const isOutdated = moment(new Date()).isAfter(endDate);

  const permissions = defaultValues && defaultValues.permissions;
  const allowChange = permissions && permissions.updateTask;

  const allowChangeDateStart =
    allowChange && (!isTemplate || defaultValues[SCHEDULER]);
  const allowChangeEstimate = !!permissions && permissions.updateTaskEstimate;
  const allowChangeResponsible =
    permissions && permissions.changeTaskResponsible;
  const allowChangeController = permissions && permissions.changeTaskController;

  const [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate,
    endDate
  });

  const SelectEmployee = projectId
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;

  console.log('edit currentTaskType', currentTaskType);
  console.log('edit form values', methods.getValues());
  console.log('edit form methods', methods);

  const dateEndRules = useDateValidationRules({
    projectId,
    isBacklog,
    isTypeAgreement,
    isTemplate,
    isActiveScheduler,
    startDate
  });

  const { validateValidityDateStateValues, changeValidityDateStateValue } =
    useValidityDateFileList({
      fileList: (descriptionWatch || {}).fileList,
      onChange: updatedFileList =>
        methods.setValue(DESCRIPTION, {
          ...descriptionWatch,
          fileList: updatedFileList
        })
    });

  useEffect(() => {
    if (!defaultValues || !Object.keys(defaultValues).length) return;

    if (currentTaskType === defaultValues.kind) return;

    setCurrentTaskType(defaultValues.kind);
  }, [defaultValues]);

  useEffect(() => {
    if (!isActiveScheduler && isTemplate) {
      methods.setValue(TYPE_DATE_START, null);
      methods.setValue(TYPE_DATE_END, null);
    }
  }, [isActiveScheduler, isTemplate]);

  useEffect(() => {
    if (selectedDates.jsEvent) {
      methods.setValue(TYPE_DATE_START, selectedDates.start);
      methods.setValue(TYPE_DATE_END, selectedDates.end);
    }
  }, [selectedDates]);

  useEffect(() => {
    if (defaultValues.dateStart && defaultValues.dateEnd) {
      selectDatesToCalendar({
        dateStart: moment(
          defaultValues.dateStart,
          DATE_TIME_FORMAT_FM
        ).toDate(),
        dateEnd: moment(defaultValues.dateEnd, DATE_TIME_FORMAT_FM).toDate()
      });
    }
  }, [defaultValues.dateEnd, defaultValues.dateStart]);

  useEffect(() => {
    if (coResponsibles) {
      setResources(
        coResponsibles.map(r => ({
          id: r.value,
          title: getFullName(r.label)
        }))
      );
    }
  }, [(coResponsibles || []).length]);

  const sortByCoResponsiblesAndController = (a, b) => {
    if (a === TYPE_CO_RESPONSIBLES || a === TYPE_CONTROLLER) {
      return -1;
    }
    if (
      b === TYPE_CO_RESPONSIBLES ||
      b === TYPE_CONTROLLER ||
      a === TYPE_MEETING_CONTACTS
    ) {
      return 1;
    }
    return 0;
  };

  const addFields = (...types) =>
    setActiveFields(
      uniq([...activeFields, ...types]).sort(sortByCoResponsiblesAndController)
    );

  const removeField = type =>
    setActiveFields(activeFields.filter(field => field !== type));

  useEffect(() => {
    if (
      isEqual(prevTaskType, currentTaskType) &&
      isEqual(prevValues, defaultValues)
    )
      return;

    setVisibleDateStart(false);

    switch (currentTaskType) {
      case TYPE_MEETING:
        setVisibleDateStart(true);
        break;
      default:
        break;
    }

    const fullFilledFields = getFullFilledFields({
      values: defaultValues,
      activeFields
    });
    const fieldsBYTypes = getFieldsByTypes(currentTaskType, activeFields);

    if (fullFilledFields.includes(TYPE_DATE_START)) setVisibleDateStart(true);
    if (fullFilledFields.includes(TYPE_TIME)) setVisibleEstimate(true);

    addFields(...union(fullFilledFields, fieldsBYTypes));

    if (defaultValues.isScheduler) {
      addFields(TYPE_DATE_START);
      setVisibleDateStart(true);
    }
  }, [defaultValues, currentTaskType]);

  const handleSubmit = async value => {
    const isValidValidityDates = validateValidityDateStateValues();

    if (!isValidValidityDates) {
      return;
    }

    const transformedValues = transformSubmittedValues({
      values: value,
      activeFields,
      currentTaskType,
      allowEditProject,
      allowEditSprint
    });

    await onSubmit({
      values: transformedValues
    });

    amplitude.updateTaskEvent({
      task: prevValues,
      value: {
        ...value,
        responsible: (value.responsible || {}).value
      },
      isTemplate
    });
  };

  const changeDateEnd = date => {
    const result =
      !endDate && !moment(date).toDate().getHours()
        ? new Date(moment(date).toDate().setHours(23, 45))
        : date;

    if (!startDate) {
      goToDate(result);
    }

    selectDatesToCalendar({ dateStart: startDate, dateEnd: result });

    return result;
  };

  const actions = [
    {
      type: TYPE_DATE_START,
      title: t(isTypeMeeting ? 'StartDateMeet' : 'StartDate'),
      onClick: () => {
        addFields(TYPE_DATE_START);
        setVisibleDateStart(true);
      },
      icon: 'calendar',
      allow: allowChangeDateStart,
      visibility: isTypeTask || isTypeAgreement
    },
    {
      type: TYPE_ADDRESS,
      title: t('AddressHeading'),
      onClick: () => addFields(TYPE_ADDRESS),
      icon: 'environment',
      allow: true,
      visibility: isTypeTask || isTypeMeeting,
      component: (
        <div className={styles.flexWrap} data-qa="qa-pmdbroh78r37ims">
          <FormLocationInput
            label={t('MainAddress')}
            name="location"
            itemProps={{ className: styles.location }}
            data-qa="qa-vovk3v36jhc2e7h"
          />

          <FormInput
            label={t('AdditionalAddress')}
            name="locationExact"
            rules={{
              maxLength: validateMaxLength(25)
            }}
            autoComplete="off"
            data-qa="qa-f6ngi1txe095a5v"
            allowClear={false}
            itemProps={{ className: styles.locationExact }}
          />
        </div>
      ),
      removeFieldCallback: () => {
        methods.setValue('location', '');
        methods.setValue('locationExact', '', { shouldValidate: true });
      }
    },
    {
      type: TYPE_CO_RESPONSIBLES,
      allow: true,
      visibility: isTypeAcquaintence || isTypeMeeting,
      component: (
        <ActionTypeCoResponsibles
          projectId={projectId}
          label={t(isTypeAcquaintence ? 'Acquainted' : 'Employees')}
        />
      )
    },
    {
      type: TYPE_MEETING_CONTACTS,
      allow: true,
      visibility: isTypeMeeting,
      component: (
        <FormContactSelect
          name={TYPE_MEETING_CONTACTS}
          label={t('ContactsOptional')}
          isMulti
          closeMenuOnSelect={false}
        />
      )
    },
    {
      type: TYPE_CONTROLLER,
      title: t('Controller'),
      onClick: () => addFields(TYPE_CONTROLLER),
      icon: 'user-add',
      allow: allowChangeController,
      visibility: !isTypeAgreement && !isTypeAcquaintence && !isTypeMeeting,
      component: <ActionTypeController projectId={projectId} />,
      removeFieldCallback: () => {
        methods.setValue(TYPE_CONTROLLER, null);
      }
    },
    {
      type: TYPE_APPROVING_MANAGER,
      title: t('Approving'),
      onClick: () => addFields(TYPE_APPROVING_MANAGER),
      icon: 'audit',
      allow: true,
      visibility: isTypeTask && (defaultValues || {}).approvingManager,
      component: (
        <SelectEmployee
          name={TYPE_APPROVING_MANAGER}
          label={t('Approving')}
          projectId={projectId}
          data-qa="qa-17nxn3r56d0s3cd"
          rules={{
            validate: {
              required: value => {
                if (
                  !value?.value &&
                  activeFields.includes(TYPE_APPROVING_MANAGER)
                )
                  return validateRequired();
                return true;
              }
            }
          }}
        />
      ),
      removeFieldCallback: () => {
        methods.setValue(TYPE_APPROVING_MANAGER, null, {
          shouldValidate: true
        });
      }
    },
    {
      type: TYPE_TIME,
      title: t('EstimatedTime'),
      onClick: () => {
        addFields(TYPE_TIME);
        setVisibleEstimate(true);
      },
      icon: 'timer',
      allow: allowChangeEstimate,
      visibility: true
    },
    {
      type: TYPE_PROJECT,
      title: t('Project'),
      onClick: () => addFields(TYPE_PROJECT),
      icon: 'project',
      hideDeleteBtn: isBacklog || !allowEditProject,
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: 2
      },
      component: (
        <>
          <Text className={styles.subTitle}>{t('Project')}</Text>

          <div className={styles.projectFields}>
            <FormProjectSelect
              name="project"
              label={t('Project')}
              params={{
                isActive: true
              }}
              isClearable={!isBacklog}
              isDisabled={!allowEditProject}
              callback={() => methods.setValue('sprint', null)}
            />

            {project?.label?.sprintsCount > 0 && allowEditSprint && (
              <FormSprintSelect
                name="sprint"
                label={t('Sprint')}
                params={{
                  project: projectId,
                  status: ['active', 'planned'],
                  isLag: false
                }}
              />
            )}
          </div>
        </>
      )
    },
    {
      type: TYPE_RELATIONS,
      title: t('LinksHeading'),
      onClick: () => addFields(TYPE_RELATIONS),
      icon: 'link',
      allow: isTemplate,
      visibility: isTemplate,
      deleteBtnStyle: {
        top: -8
      },
      component: (
        <>
          <Text
            className={classnames(styles.subTitle)}
            style={{ marginBottom: 16, display: 'block' }}
            data-qa="qa-tolj1gemcntd5ei"
          >
            {t('LinksHeading')}
          </Text>

          <FormRelationsSelect
            name="relations"
            label={
              <LabelWithTooltip
                label={t('LinkType', { ns: 'TaskLinks' })}
                tooltip={t('TaskLinksDesc', { ns: 'TaskLinks' })}
              />
            }
            itemProps={{
              className: styles.relationsSelect
            }}
            hiddenTypes={[
              ...(isTypeMeeting ? [TYPE_CONTACT] : []),
              ...(!isTypeTask ? [TYPE_ASSET] : [])
            ]}
          />
        </>
      )
    },
    {
      type: TYPE_COMPLETE_REQUIRE,
      title: t('TaskResult'),
      onClick: () => addFields(TYPE_COMPLETE_REQUIRE),
      icon: 'diff',
      allow: true,
      visibility: isTypeTask,
      component: (
        <>
          <FormCompleteRequireTaskSelect
            label={t('TaskResultHeading')}
            name={TYPE_COMPLETE_REQUIRE}
          />
        </>
      )
    }
  ].sort((a, b) => alphabeticallySort(a, b, 'title'));

  const renderTitle = () => (
    <div className={styles.field} data-qa="qa-d8fw0lok518ow1u">
      <FormInput
        name={TITLE}
        label={t('TaskName')}
        rules={{
          required: validateRequired(),
          minLength: validateMinLength(2)
        }}
        className={styles.title}
        autoFocus
        autoComplete="off"
        disabled={(isTypeAgreement || isTypeAcquaintence) && !isTemplate}
      />
    </div>
  );

  const renderDescription = () => (
    <div className={styles.field} data-qa="qa-yvmbmxrfe6s9ats">
      <FormNewEditor
        name={DESCRIPTION}
        label={t('TaskDescriptionMobile')}
        placeholder={t('TaskDescriptionPlchldr')}
        actionsDeps={{
          taskId: (defaultValues || {}).id,
          sendCopyToComment: false
        }}
        destination={{
          entityType: TYPE_TASK,
          entityId: (defaultValues || {}).id
        }}
        resizeInput
        allowManageSubscribers
        hideSubscribersAction
        hideValidityDateAction
        validityDateDeps={{ changeValidityDateStateValue }}
        highlightAttachmentsBackground
        mentionClassName={styles.mentionWrap}
        readOnly={isTypeAgreement || isTypeAcquaintence}
        isDisabled={(isTypeAgreement || isTypeAcquaintence) && !isTemplate}
      />
    </div>
  );

  const renderResponsible = () => {
    const isMultiplyResponsible = Array.isArray(defaultValues.responsible);

    return (
      <ActionTypeResponsible
        projectId={projectId}
        isMulti={isMultiplyResponsible}
        isDisabled={!allowChangeResponsible}
      />
    );
  };

  const renderAgreementSteps = () => {
    if (isTypeAgreement) {
      return <AgreementSteps />;
    }

    return null;
  };

  const renderDates = () => (
    <div className={styles.dates} data-qa="qa-v5k4pfxja3nbndv">
      {visibleDateStart && (
        <div className={styles.dateStart} data-qa="qa-pihxbjrb94sdm36">
          <FormDatePicker
            name={TYPE_DATE_START}
            label={t(isTypeMeeting ? 'StartDateMeet' : 'StartDate')}
            rules={{
              required: {
                value:
                  (isPeriodic && isActiveScheduler) ||
                  (!isTemplate && isTypeMeeting),
                message: validateRequired()
              }
            }}
            showTimeSelect
            timeIntervals={15}
            wrapperClassname={styles.datePicker}
            data-qa="qa-wg5hts5kj1a8lvs"
            dateFormat={DATE_PICKER_TIME_FORMAT}
            minDate={new Date()}
            maxDate={isOutdated ? undefined : endDate}
            minTime={minTimeStart}
            maxTime={maxTimeStart}
            openToDate={
              moment(startDate).isAfter(new Date()) ? startDate : new Date()
            }
            callback={date => {
              selectDatesToCalendar({
                dateStart: date,
                dateEnd: endDate
              });
              goToDate(date);
            }}
            defaultToMidnight={isTypeMeeting}
          />

          {isAllowedToDelete(TYPE_DATE_START, currentTaskType) &&
            !isPeriodic && (
              <Button
                type="text"
                icon={<CloseIcon />}
                className={styles.btnDelete}
                data-qa="qa-1auv4jgdhfcz0tn"
                onClick={() => {
                  removeField(TYPE_DATE_START);
                  setVisibleDateStart(false);
                }}
              />
            )}
        </div>
      )}

      <FormDatePicker
        name={TYPE_DATE_END}
        label={t(isTypeMeeting ? 'DueDateMeet' : 'TaskDueDate')}
        itemProps={{
          className: styles.dateEnd
        }}
        rules={dateEndRules}
        showTimeSelect
        timeIntervals={15}
        wrapperClassname={styles.datePicker}
        dateFormat={DATE_PICKER_TIME_FORMAT}
        minDate={startDate || new Date()}
        minTime={minTimeEnd}
        maxTime={maxTimeEnd}
        disabled={(isTemplate && !defaultValues[SCHEDULER]) || isSlaTask}
        openToDate={isOutdated ? new Date() : undefined}
        callback={changeDateEnd}
      />

      {isTemplate && (
        <FormCheckbox
          name={SCHEDULER}
          itemProps={{
            style: {
              marginLeft: 'auto',
              marginTop: 25
            }
          }}
          disabled={isTemplate || isSlaTask}
        >
          {t('RepeatTaskChckbx')}
        </FormCheckbox>
      )}
    </div>
  );

  const renderEstimate = () => (
    <div
      className={classnames(styles.field, styles.timesetField)}
      data-qa="qa-ed7qfsmt0j50gbn"
    >
      <Text className={styles.subTitle} data-qa="qa-on5j6eyngjeerw6">
        {t('EstimatedTime')}
      </Text>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          marginTop: 16
        }}
      >
        {isStoryPointEstimation ? (
          <FormInput
            itemProps={{
              style: { maxWidth: 184 }
            }}
            label={t('Storypoints')}
            name="storyPoint"
            rules={{
              pattern: validateIsNumber()
            }}
            size="default"
            placeholder="000"
          />
        ) : (
          <div className={styles.timeset} data-qa="qa-fdatgk2o8m6vhmf">
            <FormInput
              label={t('Hours')}
              name="hours"
              rules={{
                pattern: validateIsNumber(),
                maxLength: validateMaxLength(3)
              }}
              size="default"
              placeholder="000"
              data-qa="qa-l2xkyoh6t3se8nj"
            />

            <FormInput
              label={t('Minutes')}
              name="minutes"
              rules={{
                pattern: validateIsNumber(),
                maxLength: validateMaxLength(3)
              }}
              size="default"
              placeholder="000"
              data-qa="qa-l2xkyoh6t3se8nj"
            />
          </div>
        )}

        {!defaultValues.parent && (
          <FormSwitch
            itemProps={{
              style: { margin: '27px 0 0 0', width: 'auto' }
            }}
            name="isStoryPointEstimation"
            label={t('ChangeToStorypointsToggle')}
          />
        )}
      </div>

      <Button
        type="text"
        icon={<CloseIcon />}
        className={styles.btnDelete}
        style={{
          top: 2
        }}
        data-qa="qa-suyy8dz4f7mlr3k"
        onClick={() => {
          removeField('time');
          setVisibleEstimate(false);

          methods.setValue('storyPoint', undefined, { shouldValidate: true });
          methods.setValue('hours', null, { shouldValidate: true });
          methods.setValue('minutes', null, { shouldValidate: true });
          methods.setValue('isStoryPointEstimation', false);
        }}
      />
    </div>
  );

  const filteredActions =
    defaultValues &&
    actions
      .filter(action => !activeFields.includes(action.type))
      .filter(action => action.visibility)
      .filter(action => action.title);

  return (
    <FormProvider {...methods}>
      <form
        className={styles.content}
        data-qa="qa-ojf06bmx5f4juyu"
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(handleSubmit))
        }
      >
        <div className={styles.typeTaskSelect} data-qa="qa-c3hr6zfxbr74kjs">
          <Text className={styles.subTitle} data-qa="qa-hx1nkwoq859vuwa">
            {t('TaskTypeHeading')}
          </Text>

          <div className={styles.selectTypeWrap}>
            <TaskTypeSelect
              onChange={setCurrentTaskType}
              data-qa="qa-kwkfml5qexo6zxn"
              disabled
              defaultValue={
                defaultValues.kind === TYPE_AGREEMENT_TASK
                  ? TYPE_TASK
                  : defaultValues.kind
              }
            />
          </div>
        </div>

        <div className={styles.fieldsWrap} data-qa="qa-nptmk7n1yn8ufnu">
          {defaultValues.isScheduler && (
            <FormSwitch
              name="isActiveScheduler"
              label={t('TemplateRepeatToggle', {
                ns: 'EditTaskTemplate'
              })}
            />
          )}

          {renderTitle()}

          {renderDescription()}

          {renderDates()}

          {isSlaTask && (
            <Alert
              type="warning"
              message={t('SLADateWarning')}
              className={styles.slaAlert}
            />
          )}

          {isTypeMeeting && meetingCalendar && (
            <Button
              type="secondary"
              size="large"
              className={styles.visibleCalendarBtn}
              onClick={() =>
                meetingCalendar.setVisible(!meetingCalendar.visible)
              }
            >
              {t(
                meetingCalendar.visible ? 'HideCalendarBtn' : 'OpenCalendarBtn'
              )}
            </Button>
          )}

          {isPeriodic && isTemplate && (
            <IntervalSelect defaultValues={defaultValues} />
          )}

          {visibleEstimate && renderEstimate()}

          {!hideResponsibleField && renderResponsible()}

          {renderAgreementSteps()}

          {(activeFields || []).map(field => {
            const action = actions
              .filter(item => item.visibility)
              .find(a => a.type === field);

            if (action && field !== 'dateStart' && field !== 'time') {
              return (
                <div
                  key={field}
                  className={styles.field}
                  data-qa="qa-ub8mqlbdzsakvye"
                >
                  {action.component}

                  {isAllowedToDelete(field, currentTaskType) &&
                    !action.hideDeleteBtn && (
                      <Button
                        type="text"
                        icon={<CloseIcon />}
                        className={styles.btnDelete}
                        style={action.deleteBtnStyle}
                        data-qa="qa-wzdz0dd8bd9c6bk"
                        onClick={() => {
                          removeField(field);
                          action?.removeFieldCallback?.();
                        }}
                      />
                    )}
                </div>
              );
            }
            return undefined;
          })}
        </div>

        <Divider className={styles.divider} data-qa="qa-a7wjybuw5tx7cpb" />

        <ActionsDropdown
          actions={filteredActions}
          placement="bottomLeft"
          btnClassName={styles.btnDropdown}
          overlayClassName={styles.dropdownOverlay}
          data-qa="qa-bthkgksizx45as2"
        >
          <Button
            type="link"
            style={{
              fontSize: 16,
              display: 'flex',
              alignItems: 'center',
              padding: 0
            }}
            data-qa="qa-n1dxcbul5efsb0x"
          >
            <Icon type="plus" /> {t('ShowAllFieldsBtn')}
          </Button>
        </ActionsDropdown>

        <div className={styles.btnStepsWrap}>
          <Button
            className={styles.btnSteps}
            data-qa="qa-nlsx1e9ljbre2yd"
            disabled={isSubmitting || isFilesLoading}
            loading={isSubmitting}
            htmlType="submit"
            type="primary"
            size="large"
          >
            {t('SaveBtn')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default BaseForm;
