import React from 'react';
import { Translation } from 'react-i18next';
import i18next from 'i18next';

import isValidPhoneNumber from 'components/common/controls/phone-input/helpers';
import moment from 'moment';

export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const numberOrLatinPattern = {
  value: new RegExp(/^([aA-zZ0-9])+$/),
  message: (
    <Translation ns="Errors">{t => t('NumberOrLatinPattern')}</Translation>
  )
};

export const validateCompany = value => {
  if (!value.company) {
    return i18next.t('ValidateCompanyEmpty', { ns: 'Errors' });
  }

  if (!value.company.exist === false) {
    return '';
  }

  return true;
};

export const validateEmail = (email, message) => {
  if (email && !email.match(emailRegex)) {
    return message || i18next.t('ValidateEmail', { ns: 'Errors' });
  }

  return true;
};

export const validatePhone = (value, options, message) => {
  if (!isValidPhoneNumber(options?.isOnlyNumber ? value : value.number)) {
    return message || i18next.t('EnterAValidPhone', { value, ns: 'Errors' });
  }

  return true;
};

export const validateMinLength = value => ({
  value,
  message: i18next.t('ValidateMinLength', { value, ns: 'Errors' })
});

export const validateMaxLength = value => ({
  value,
  message: i18next.t('ValidateMaxLength', { value, ns: 'Errors' })
});

export const validateRequired = () =>
  i18next.t('RequiredField', { ns: 'Errors' });

export const validateIsNumber = () => ({
  value: new RegExp(/^([0-9])+$/),
  message: i18next.t('NumberPattern', { ns: 'Errors' })
});

export const makeValidateDatesStartAfterEnd = ({
  start,
  end,
  checkSameDates,
  checkSameFormat
}) => {
  const isAfter = start && moment(start).isAfter(end);
  const isSame = checkSameDates
    ? start && moment(start).isSame(end, checkSameFormat)
    : false;

  if (isSame) {
    return i18next.t('DateStartCannotBeEqualDateEnd', { ns: 'Errors' });
  }

  if (isAfter) {
    return i18next.t('DateStartCannotBeAfterDateEnd', { ns: 'Errors' });
  }

  return true;
};
