import React, { useState } from 'react';
import { Input, Alert, Checkbox } from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Translation, useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';

import {
  TYPE_DATE_END,
  TYPE_DATE_START,
  SCHEDULED_TASK_TYPES,
  SCHEDULED_TASK_WEEK,
  DATE_TIME_FORMAT_FM,
  VIDEOS,
  SCHEDULED_TASK_DAY
} from 'constants/index';

import Typography from 'components/common/typography';
import VideoBtn from 'components/common/video-btn';
// eslint-disable-next-line import/no-cycle
import {
  FormAntdSelect,
  FormInputNumber,
  validateMinLength
} from 'components/common/hook-form';

import { fetchScheduledTaskNextDateLocal } from 'store/tasks';

import useDeepCompareEffect from 'hooks/common/use-deep-compare-effect';

import styles from './styles.module.scss';

const { Text } = Typography;

export const IntervalSelect = ({ defaultValues }) => {
  const dispatch = useDispatch();
  const methods = useFormContext();

  const defaultFreqType =
    (defaultValues.schedulerConfig || {}).freqType || SCHEDULED_TASK_WEEK;

  const freqType = methods.watch('freqType');
  const weekdays = methods.watch('weekdays');
  const freqInterval = methods.watch('freqInterval');
  const dateEnd = methods.watch(TYPE_DATE_END);
  const dateStart = methods.watch(TYPE_DATE_START);

  const selectOptions = Object.values(SCHEDULED_TASK_TYPES);
  const freqTypeOptions =
    SCHEDULED_TASK_TYPES[freqType || defaultFreqType].options;

  const [nextTaskDate, setNextTaskDate] = useState(null);

  const { t } = useTranslation('AddTask');

  const canShowNextTaskDate =
    !!freqInterval &&
    dateEnd &&
    (!freqTypeOptions || (weekdays || []).length > 0);

  useDeepCompareEffect(() => {
    if (!canShowNextTaskDate) {
      setNextTaskDate(null);
      return;
    }

    if (!dateEnd || !dateStart) {
      return;
    }

    dispatch(
      fetchScheduledTaskNextDateLocal({
        dateEnd: dateEnd && moment(dateEnd).toISOString(true),
        dateStart: dateStart && moment(dateStart).toISOString(true),
        freqType,
        freqInterval,
        weekdays
      })
    ).then(({ nextDateEnd, nextDateStart }) => {
      setNextTaskDate({
        dateEnd: nextDateEnd && moment(nextDateEnd).format(DATE_TIME_FORMAT_FM),
        dateStart:
          nextDateStart && moment(nextDateStart).format(DATE_TIME_FORMAT_FM)
      });
    });
  }, [dateStart, dateEnd, freqInterval, weekdays, freqType, freqTypeOptions]);

  const renderNextCreateDate = ({ nextTaskDateStart }) => {
    const isOneDayPeriod =
      freqType === SCHEDULED_TASK_DAY && freqInterval === 1;

    return moment(nextTaskDateStart, DATE_TIME_FORMAT_FM)
      .subtract(1, isOneDayPeriod ? 'hours' : 'days')
      .format(DATE_TIME_FORMAT_FM);
  };

  const freqTypeSelectOptions = selectOptions.map(({ value, ns, label }) => ({
    label: <Translation ns={ns}>{translate => translate(label)}</Translation>,
    value,
    id: value
  }));

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Text
          size="small"
          color="black-55"
          style={{ marginBottom: 4 }}
        >
          {t('RecurrenceField')}
        </Typography.Text>

        <Input.Group compact>
          <FormInputNumber
            name="freqInterval"
            rules={{
              minLength: validateMinLength(1)
            }}
            data-qa="qa-85xh63t47670ipf"
            itemProps={{
              className: styles.delay
            }}
          />

          <FormAntdSelect
            name="freqType"
            dropdownMatchSelectWidth={false}
            className={styles.repeatInterval}
            options={freqTypeSelectOptions}
          />

          <VideoBtn
            slug={VIDEOS.recurringTask}
            style={{ display: 'flex', position: 'absolute', right: 0, top: 0 }}
          />
        </Input.Group>
      </div>

      {freqTypeOptions && (
        <div style={{ marginBottom: 16 }}>
          <Controller
            name="weekdays"
            control={methods.control}
            render={({ field }) => (
              <Checkbox.Group
                {...field}
                className={styles.weekdays}
                data-qa="qa-dfb6qacksgzc4ns"
                options={freqTypeOptions.map(f => ({
                  ...f,
                  label: (
                    <Translation ns={f.ns}>
                      {translate => translate(f.label)}
                    </Translation>
                  )
                }))}
              />
            )}
          />
        </div>
      )}

      {!canShowNextTaskDate && (
        <Alert
          style={{ marginBottom: 15 }}
          data-qa="qa-i3i70kg23zaj1uj"
          message={t('RepeatTaskWarning')}
          type="warning"
        />
      )}

      {canShowNextTaskDate && nextTaskDate && (
        <Alert
          style={{ marginBottom: 15, fontSize: 12 }}
          data-qa="qa-wo42mx1x6djppqr"
          message={
            <>
              {t('NextTaskCreateWarning')}{' '}
              <Text strong>
                {renderNextCreateDate({
                  nextTaskDateStart: nextTaskDate.dateStart
                })}
              </Text>{' '}
              {t('NextTaskCreateTermsWarning')} <br />
              {nextTaskDate.dateStart && (
                <>
                  {t('NextTaskCreateStartWarning')}{' '}
                  <Text strong>{nextTaskDate.dateStart}</Text> <br />
                </>
              )}
              {t('NextTaskCreateDueWarning')}{' '}
              <Text strong>{nextTaskDate.dateEnd}</Text>
            </>
          }
          type="warning"
        />
      )}
    </div>
  );
};

export default IntervalSelect;
