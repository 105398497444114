import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';

import Icon from 'components/common/icon';
// eslint-disable-next-line import/no-cycle
import {
  FormEmployeeSelect,
  validateRequired
} from 'components/common/hook-form';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import styles from './agreement-steps.module.scss';

const { Title, Paragraph } = Typography;

const getNewStep = () => ({
  employees: [],
  isEdit: true
});

export const AgreementSteps = () => {
  const methods = useFormContext();

  const { t } = useTranslation('AddTask');

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'agreementSteps'
  });

  const onAddStep = () => append(getNewStep());
  const onDeleteStep = index => remove(index);

  return (
    <div className={styles.root} data-qa="qa-zcvchofb9s0ksq7">
      <Title level={4} style={{ fontSize: 16 }}>
        {t('AgreementProcedureHeading')}
      </Title>
      <Paragraph style={{ marginBottom: 10 }}>
        {t('AgreementProcedureDesc')}
      </Paragraph>

      <div className={styles.steps} data-qa="qa-wxwakqdgi344z31">
        {fields.map((agreement, index) => (
          <div
            key={agreement.id}
            className={classnames(styles.step, {
              [styles.lastStep]: index === fields.length - 1,
              [styles.disabled]: !agreement.isEdit
            })}
            data-qa="qa-6u8s07rzgkw2y5d"
          >
            <Paragraph
              style={{ marginBottom: 8 }}
              className={styles.flexWrap}
              data-qa="qa-arz6yiz3vxwjh3y"
            >
              <span>
                {t('Step')} {index + 1}
              </span>
              {fields.length > 1 && (
                <Button
                  type="link"
                  size="small"
                  onClick={() => onDeleteStep(index)}
                  className={styles.closeBtn}
                  data-qa="qa-rkaccm9wg50gn06"
                >
                  <Icon type="close" />
                </Button>
              )}
            </Paragraph>

            <FormEmployeeSelect
              name={`agreementSteps.${index}.employees`}
              label={t(index === 0 ? 'Coordinators' : 'NextCoordinators')}
              data-qa="qa-19f5v91pwhzog0n"
              isMulti
              allowSelectAll
              showSelectedOptionsOnList={false}
              rules={{
                required: validateRequired()
              }}
            />
          </div>
        ))}

        <Button
          type="link"
          style={{ paddingLeft: 0 }}
          onClick={onAddStep}
          data-qa="qa-qyfdf32ny6xnzud"
        >
          {t('AddStepBtn')}
        </Button>
      </div>
    </div>
  );
};

export default AgreementSteps;
