import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import { STATUS_CHECK, VIDEOS } from 'constants/index';

import Modal from 'components/common/modal';
import VideoBtn from 'components/common/video-btn';
import Button from 'components/common/button';
import FormProjectParticipantsSelect from 'components/common/hook-form/select/project-participants';
import { FormEmployeeSelect } from 'components/common/hook-form';

import { changeController, fetchTask } from 'store/tasks';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import useRoutesService from 'services/routes';
import { showNoticeMessage } from 'services/notice';

const EditorForm = ({ task = {}, hasController, onCallback }) => {
  const dispatch = useDispatch();
  const amplitude = useAmplitude();
  const routes = useRoutesService();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['TaskRoleActions', 'Toast', 'Common']);

  const { handleSubmit, ...methods } = useForm({
    defaultValues: {
      controller: hasController && {
        value: (task.controller || {}).id,
        label: task.controller
      }
    }
  });

  const onSave = async values => {
    try {
      setIsLoading(true);

      await dispatch(
        changeController({
          id: task.id,
          employeeId: (values.controller || {}).value || null
        })
      );

      await dispatch(fetchTask({ id: task.id })).catch(({ response }) => {
        if (response.status === 403) {
          routes.toTasks();
        }
      });

      let noticeTKey = hasController ? 'ControllerChanged' : 'ControllerAdded';

      if (hasController && !(values.controller || {}).value) {
        noticeTKey = 'ControllerDeleted';
      }

      amplitude.updateTaskEvent({
        task,
        value: { controller: (values.controller || {}).value || null }
      });

      showNoticeMessage({
        customContent: t(noticeTKey, { ns: 'Toast' })
      });

      onCallback?.();
    } finally {
      setIsLoading(false);
    }
  };

  const isCheckType = task.status === STATUS_CHECK;

  const SelectEmployee = (task.project || {}).id
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={handleSubmit(onSave)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 24px 24px'
        }}
        data-qa="qa-pa1kj1t3l3959uw"
      >
        <VideoBtn
          slug={VIDEOS.taskControllerRole}
          style={{ marginBottom: 20 }}
        />

        <SelectEmployee
          name="controller"
          label={t('Controller')}
          projectId={(task.project || {}).id}
          valueText={t('ChooseController', { ns: 'Common' })}
          hideWorkspace
          isClearable={!isCheckType}
          closeMenuOnSelect
        />

        <Button
          type="primary"
          data-qa="qa-okrl4246omkkcf2"
          htmlType="submit"
          loading={isLoading}
          style={{ marginLeft: 'auto' }}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

export const EditorControllerModal = ({ onClose, task, ...props }) => {
  const { t } = useTranslation('TaskRoleActions');

  const hasController = !!(task || {}).controller;

  return (
    <Modal
      width={540}
      title={t(
        hasController ? 'ChangeControllerHeading' : 'AddControllerHeading'
      )}
      data-qa="qa-xaigeb0dy0hcip1"
      onClose={onClose}
      {...props}
    >
      <EditorForm
        task={task}
        hasController={hasController}
        onCallback={onClose}
      />
    </Modal>
  );
};

export default EditorControllerModal;
