import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import { transformRelationsToSelectValue } from 'components/common/controls/custom-select/relations-select/utils';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';
import useValidityDate from 'components/common/validity-date/use-validity-date';
import { SkeletonEntityCreation } from 'components/common/skeletons';
import Drawer from 'components/common/drawer';
import useTemplateRelations from 'components/tasks-view/template-view/use-template-relations';

import {
  editTemplateFieldsLocal,
  fetchTemplateAgreementStepsLocal,
  fetchTemplateRelationsLocal
} from 'store/tasks';

import { showNoticeMessage } from 'services/notice';
import { getFileIds } from 'hooks/common/use-file-upload/get-file-ids';

// eslint-disable-next-line import/no-cycle
import BaseForm from '../../view/drawers/editor/base-form';
import transformTask from '../../../../utils/transform-task';
import {
  clearAccordingType,
  getTaskValue
} from '../../view/drawers/creator/utils';

const getEditTemplatePermissions = ({ isPeriodical }) => ({
  changeDateStart: !!isPeriodical,
  changeDateEnd: !!isPeriodical,
  updateTask: true,
  updateTaskEstimate: false,
  addTaskMember: true,
  deleteTaskMember: true,
  changeTaskResponsible: true,
  changeTaskController: true,
  createRelation: true,
  deleteRelation: true
});

const TemplateEditorDrawer = ({
  template: _template,
  visible,
  onClose,
  callback,
  ...drawerProps
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['AddTask', 'Toast']);
  const { updateTemplateRelations } = useTemplateRelations();
  const { manageBulkSubscribers } = useManageSubscribers();
  const { handleChangeBulkValidityDates } = useValidityDate();

  const [template, setTemplate] = useState(_template);
  const [relations, setRelations] = useState();
  const [steps, setSteps] = useState([]);
  const [transformedTemplate, setTransformedTemplate] = useState(null);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [isLoadingDeps, setIsLoadingDeps] = useState(true);

  useEffect(() => {
    if (_template) {
      setTemplate(_template);
    }
  }, [_template]);

  useEffect(() => {
    if (!isEmpty(template)) {
      setTransformedTemplate(
        transformTask({
          ...template,
          permissions: getEditTemplatePermissions(template),
          members: template.members,
          relations,
          agreementSteps: steps
        })
      );
    }
  }, [template, relations, steps]);

  const dispatchNewFields = async values => {
    const resultTask = getTaskValue(clearAccordingType(values));

    const value = {
      id: template.id,
      data: {
        ...resultTask,
        responsible: (values.responsible || []).map(r => r.value),
        controller: (values.controller || {}).value || null,
        description: values.description || undefined,
        dateEnd: values.dateEnd || null,
        dateStart: values.dateStart || null,
        fileList: getFileIds(values.fileList),
        members: (values.members || []).map(member => member.value)
      }
    };

    return dispatch(editTemplateFieldsLocal({ value }));
  };

  const sendValues = async ({ values }) => {
    try {
      setIsLoadingSend(true);

      const updatedTemplate = await dispatchNewFields(values);

      await updateTemplateRelations(
        relations || [],
        values.relations || [],
        template.id
      );

      await callback();

      showNoticeMessage({
        customContent: t('TemplateEdited', { ns: 'Toast' })
      });

      await manageBulkSubscribers(values.fileList);

      await handleChangeBulkValidityDates({
        fileList: values.fileList,
        isFromEditor: true
      });

      onClose(updatedTemplate);
    } finally {
      setIsLoadingSend(false);
    }
  };

  useEffect(() => {
    const fetchDependencies = async () => {
      try {
        setIsLoadingDeps(true);

        const templateRelations = await dispatch(
          fetchTemplateRelationsLocal({ id: template.id })
        );

        const { agreementSteps } = await dispatch(
          fetchTemplateAgreementStepsLocal({ id: template.id })
        );

        const transformSteps = (agreementSteps || []).map((step, index) => ({
          isEdit: true,
          employees: step[index].map(e => ({ value: e.id, label: e }))
        }));

        setRelations(transformRelationsToSelectValue(templateRelations));
        setSteps(transformSteps);
      } finally {
        setIsLoadingDeps(false);
      }
    };

    if (visible && !!template?.id) {
      fetchDependencies();
    }
  }, [visible, template?.id]);

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      destroyOnClose
      data-qa="qa-v57huwy3lgghdm1"
      open={visible}
      title={<Drawer.Title>{t('EditTaskTemplateHeading')}</Drawer.Title>}
      onClose={() => onClose()}
      {...drawerProps}
    >
      <>
        {isLoadingDeps && <SkeletonEntityCreation />}

        {!isEmpty(transformedTemplate) && !isLoadingDeps && (
          <BaseForm
            onSubmit={sendValues}
            defaultValues={transformedTemplate}
            isSubmitting={isLoadingSend}
            isTemplate
          />
        )}
      </>
    </Drawer>
  );
};

TemplateEditorDrawer.propTypes = {
  template: PropTypes.shape({}),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func
};

TemplateEditorDrawer.defaultProps = {
  template: {},
  callback: () => {}
};

export default TemplateEditorDrawer;
