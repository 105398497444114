import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

// eslint-disable-next-line import/no-cycle
import {
  AllWorkspacesSelect,
  ContactSelect
} from 'components/common/controls/custom-select';
import { FormItem } from 'components/common/hook-form';

const FormCounterpartSelect = ({
  rules,
  name,
  label,
  itemProps,
  isToWorkspace,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) =>
          isToWorkspace ? (
            <AllWorkspacesSelect
              label=""
              value={value}
              onChange={onChange}
              {...props}
            />
          ) : (
            <>
              <ContactSelect
                label=""
                isMulti={false}
                data-qa="qa-pu93742n1lrq9og"
                value={value}
                onChange={onChange}
                {...props}
              />
            </>
          )
        }
      />
    </FormItem>
  );
};

export default FormCounterpartSelect;
