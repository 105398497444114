// eslint-disable-next-line import/no-cycle
import {
  makeValidateDatesStartAfterEnd,
  validateRequired
} from 'components/common/hook-form';

export const useDateValidationRules = ({
  projectId,
  isBacklog,
  isTypeAgreement,
  isTemplate,
  isActiveScheduler,
  startDate
}) => {
  const isDateEndRequired = () =>
    (!(projectId || isBacklog) || isTypeAgreement) &&
    (!isTemplate || isActiveScheduler);

  if (!isDateEndRequired()) {
    return {};
  }

  return {
    required: validateRequired(),
    validate: value =>
      makeValidateDatesStartAfterEnd({
        start: startDate,
        end: value,
        checkSameDates: true
      })
  };
};
