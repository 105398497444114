import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';

import {
  TYPE_MEMBERS,
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_DATE_END,
  TITLE,
  TYPE_CONTACT,
  DATE_TIME_FORMAT_FM,
  RESPONSIBLE,
  SCHEDULER,
  TYPE_TAGS,
  TYPE_CO_RESPONSIBLES,
  TYPE_APPROVING_MANAGER,
  TYPE_COMPLETE_REQUIRE,
  TYPE_MEETING_CONTACTS,
  TYPE_ACCESS_TYPE,
  ACCESS_TYPE_PUBLIC,
  SCHEDULED_TASK_WEEK,
  SCHEDULED_TASK_TYPES,
  ACCESS_TYPES_OPTIONS
} from 'constants/index';

import { convertMessageToString } from 'components/common/comments/converters';

import { getUserEmployee } from 'store/workspace';

import getMembers from 'utils/get-members';
import { mapUserToSelectValue } from 'utils/map-user-to-select';
import getTimeByEstimate from 'utils/get-time-by-estimate';

const mapValueForNewFields = value => ({
  value: value.id,
  label: value
});

export const useFormDefaultValues = ({
  defaultValues,
  isTypeMeeting,
  isFromTemplate
}) => {
  const user = useSelector(getUserEmployee);

  const projectId = (defaultValues?.project || {}).value;

  const apartValues =
    defaultValues && getTimeByEstimate(defaultValues?.estimation);

  const getInitialCoResponsible = () => {
    if (isTypeMeeting && !isFromTemplate && !defaultValues?.isCopy) {
      return [mapUserToSelectValue(user)];
    }

    if ((defaultValues.coResponsibles || []).length) {
      return defaultValues.coResponsibles.map(mapUserToSelectValue);
    }

    return [];
  };

  const getInitialDateStartValue = () => {
    if (isTypeMeeting) {
      return defaultValues.dateStart;
    }

    return defaultValues.dateStart
      ? new Date(
          moment(defaultValues.dateStart, DATE_TIME_FORMAT_FM)
            .toDate()
            .getTime()
        )
      : new Date(moment().toDate().getTime());
  };

  const descriptionTask =
    typeof defaultValues.description === 'string'
      ? defaultValues.description
      : (defaultValues.description || []).filter(el => !el.link);

  const initialResponsibleValue = defaultValues.responsible || [];

  const defaultFreqInterval =
    (defaultValues.schedulerConfig || {}).freqInterval || 1;

  const defaultFreqType =
    (defaultValues.schedulerConfig || {}).freqType || SCHEDULED_TASK_WEEK;

  const getInitialAccessType = () => {
    const defaultAccessType =
      defaultValues?.parentAccessType ?? ACCESS_TYPE_PUBLIC;

    return (
      ACCESS_TYPES_OPTIONS.find(option => option.value === defaultAccessType) ||
      ACCESS_TYPES_OPTIONS[0]
    );
  };

  const getInitialValues = () => ({
    ...defaultValues,
    [TYPE_TAGS]: defaultValues?.tags
      ? defaultValues.tags.map(mapValueForNewFields)
      : [],
    location: defaultValues?.location || '',
    locationExact: defaultValues?.locationExact || '',
    [TYPE_MEMBERS]: projectId
      ? (defaultValues?.members || []).map(mapValueForNewFields)
      : getMembers(defaultValues),
    [TYPE_CO_RESPONSIBLES]: getInitialCoResponsible(),
    [TYPE_MEETING_CONTACTS]: (defaultValues.relations || [])
      .filter(item => item.type === TYPE_CONTACT)
      .map(item => ({
        label: {
          ...item.relation,
          firstName: item.relation.name
        },
        value: item.objectId
      })),
    [TYPE_CONTROLLER]: mapUserToSelectValue(defaultValues.controller),
    [TYPE_APPROVING_MANAGER]: defaultValues?.approvingManager
      ? {
          value: defaultValues.approvingManager.id,
          label: defaultValues.approvingManager
        }
      : undefined,
    storyPoint: defaultValues?.storyPoint,
    hours: apartValues.hours ? apartValues.hours.toString() : null,
    minutes: apartValues.minutes ? apartValues.minutes.toString() : null,
    isStoryPointEstimation: defaultValues?.isStoryPointEstimation,
    relations: (defaultValues.relations || []).filter(r =>
      isTypeMeeting ? r.type !== TYPE_CONTACT : true
    ),
    project: defaultValues?.project
      ? {
          value: defaultValues.project.id,
          label: defaultValues.project
        }
      : undefined,
    sprint: defaultValues?.sprint
      ? {
          value: defaultValues.sprint.id,
          label: defaultValues.sprint
        }
      : undefined,
    [TYPE_COMPLETE_REQUIRE]: defaultValues?.completeRequire,
    [TYPE_ACCESS_TYPE]: getInitialAccessType(),
    [TITLE]: defaultValues.title,
    description: {
      description: convertMessageToString(descriptionTask),
      fileList: defaultValues.fileList || []
    },
    [RESPONSIBLE]: initialResponsibleValue.map(mapUserToSelectValue),
    [TYPE_DATE_START]: getInitialDateStartValue(),
    [TYPE_DATE_END]: defaultValues.dateEnd
      ? moment(defaultValues.dateEnd).toDate()
      : defaultValues.maxDateEnd && moment(defaultValues.maxDateEnd).toDate(),
    [SCHEDULER]: (defaultValues.schedulerConfig || {}).isActive || false,
    freqInterval: defaultFreqInterval,
    freqType: defaultFreqType,
    weekdays: (defaultValues.schedulerConfig || {}).weekdays || [
      SCHEDULED_TASK_TYPES[SCHEDULED_TASK_WEEK].options[moment().day()].value
    ],
    agreementSteps: defaultValues.agreementSteps || [
      { employees: [], isEdit: true }
    ],
    updateTemplate: defaultValues.updateTemplate,
    saveAsTemplate: defaultValues.saveAsTemplate,
    discussion: defaultValues?.discussion
  });

  const methods = useForm({
    defaultValues: getInitialValues()
  });

  return methods;
};
