import {
  SCHEDULER,
  TYPE_COMPLETE_REQUIRE,
  TYPE_CONTACT,
  TYPE_DATE_START,
  TYPE_MEETING_CONTACTS,
  TYPE_PROJECT,
  TYPE_RELATIONS,
  TYPE_TIME
} from 'constants/index';

import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';

import mapValue from 'utils/map-value';

export const transformSubmittedValues = ({
  values,
  activeFields,
  currentTaskType,
  allowEditProject,
  allowEditSprint
}) => {
  const getProjectValues = () => {
    if (activeFields.includes(TYPE_PROJECT) && allowEditProject) {
      return {
        project: (values.project || {}).value || null,
        sprint: (values.sprint || {}).value || null
      };
    }

    return {
      project: allowEditProject ? null : undefined,
      sprint: allowEditSprint ? null : undefined
    };
  };

  const getRelations = () => {
    let results = [];

    if (activeFields.includes(TYPE_RELATIONS)) {
      results = [...(values.relations || [])];
    }

    if (activeFields.includes(TYPE_MEETING_CONTACTS)) {
      results = [
        ...results,
        ...transformValueToRelation(values.meetingContacts || [], TYPE_CONTACT)
      ];
    }

    return results;
  };

  return {
    // ...values,
    ...values.description,
    ...getProjectValues(),
    storyPoint: +values.storyPoint || undefined,
    relations: getRelations(),
    location: values.location || '',
    locationExact: values.locationExact || '',
    description: [
      { text: values.description.description },
      ...(values.description.links || [])
    ],
    kind: currentTaskType,
    coResponsibles: (values.coResponsibles || []).map(mapValue),
    reconsciling: (values.reconsciling || {}).value,
    approvingManager: values?.approvingManager?.value,
    completeRequire: activeFields.includes(TYPE_COMPLETE_REQUIRE)
      ? values.completeRequire
      : null,
    ...(values[SCHEDULER]
      ? {
          schedulerConfig: {
            isActive: values.isActiveScheduler,
            freqInterval: values.freqInterval,
            weekdays: values.weekdays,
            freqType: values.freqType
          }
        }
      : {}),
    hours: activeFields.includes(TYPE_TIME) ? values.hours : null,
    minutes: activeFields.includes(TYPE_TIME) ? values.minutes : null,
    isStoryPointEstimation: values?.isStoryPointEstimation,
    meetingContacts: values.meetingContacts,
    responsible: values.responsible,
    agreementSteps: values?.agreementSteps,
    dateStart: activeFields.includes(TYPE_DATE_START) ? values.dateStart : null,
    dateEnd: values.dateEnd,
    title: values.title,
    controller: values?.controller,
    estimation: values?.estimation,
    tags: values?.tags,
    isScheduler: values?.isScheduler,
    updateTemplate: values?.updateTemplate,
    saveAsTemplate: values?.saveAsTemplate,
    sendLogsToContact: values?.sendLogsToContact,
    discussion: values?.discussion,
    taskInfo: values?.taskInfo,
    channelChat: values?.channelChat,
    template: values?.template,
    attachmentVersion: values?.attachmentVersion,
    parent: values?.parent,
    workLogTotal: values?.workLogTotal,
    workLog: values?.workLog,
    estimationTotal: values?.estimationTotal,
    author: values?.author
  };
};
