import React from 'react';
import { useTranslation } from 'react-i18next';

import { TYPE_CONTROLLER } from 'constants/index';

import Typography from 'components/common/typography';
import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';
// eslint-disable-next-line import/no-cycle
import FormProjectParticipantsSelect from 'components/common/hook-form/select/project-participants';
import { FormEmployeeSelect } from 'components/common/hook-form';

const ActionTypeController = ({ projectId }) => {
  const { t } = useTranslation('AddTask');

  const SelectEmployee = projectId
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;

  const label = (
    <>
      <Typography.Text
        style={{ color: 'inherit', marginRight: 5 }}
        size="small"
      >
        {t('Controller')}
      </Typography.Text>

      <Tooltip title={t('ControllerTip')}>
        <Icon
          type="question-circle"
          size={12}
          style={{ verticalAlign: 'unset' }}
        />
      </Tooltip>
    </>
  );

  return (
    <SelectEmployee
      name={TYPE_CONTROLLER}
      label={label}
      data-qa="qa-yyh59iy14r15ule"
      projectId={projectId}
      hideWorkspace
      isMulti={false}
    />
  );
};

export default ActionTypeController;
