import { TYPE_APPROVING_MANAGER } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import {
  makeValidateDatesStartAfterEnd,
  validateRequired
} from 'components/common/hook-form';

export const useDateValidationRules = ({
  projectId,
  startDate,
  isBacklog,
  activeFields,
  isPeriodic
}) => {
  if (
    (projectId || isBacklog) &&
    !activeFields.includes(TYPE_APPROVING_MANAGER) &&
    !isPeriodic
  ) {
    return {};
  }

  return {
    required: validateRequired(),
    validate: value =>
      makeValidateDatesStartAfterEnd({
        start: startDate,
        end: value,
        checkSameDates: true
      })
  };
};
