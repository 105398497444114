import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { SLA_TYPE_OPTIONS, TYPE_ORDER_STATUS } from 'constants/index';

import CustomSelect from '../custom-select';

const SLA_UNASSIGNED = [
  {
    value: 0,
    label: 'Unassigned',
    ns: 'Common'
  }
];

export const SlaTypeSelect = forwardRef(
  (
    {
      value,
      onChange,
      isDisabled,
      isDealsObjectSlaEnabled,
      options = SLA_TYPE_OPTIONS,
      includeUnassigned = false,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation(['Requests', 'Common']);

    const filteredOptions = isDealsObjectSlaEnabled
      ? options
      : options.filter(option => option.value !== TYPE_ORDER_STATUS);

    const finalOptions = includeUnassigned
      ? [
          ...SLA_UNASSIGNED.map(option => ({
            ...option,
            label: t(option.label, { ns: option.ns })
          })),
          ...filteredOptions
        ]
      : filteredOptions;

    return (
      <CustomSelect
        ref={ref}
        optionProps={{
          style: {
            display: 'flex',
            justifyContent: 'space-between'
          }
        }}
        value={value}
        onChange={onChange}
        valueText={t('ChooseSLAEntity', { ns: 'Requests' })}
        options={finalOptions}
        isClearable
        isDisabled={isDisabled}
        {...props}
      />
    );
  }
);
