import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { RESPONSIBLE } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import FormProjectParticipantsSelect from 'components/common/hook-form/select/project-participants';
import { FormEmployeeSelect } from 'components/common/hook-form';
import Typography from 'components/common/typography';

const ActionTypeResponsible = ({
  projectId,
  isDisabled,
  defaultValues,
  ...props
}) => {
  const { t } = useTranslation('AddTask');

  const SelectEmployee = projectId
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;

  const label = (
    <>
      <Typography.Text
        size="small"
        style={{ color: 'inherit', marginRight: 5 }}
        data-qa="qa-pr8a8u5st2dyfs9"
      >
        {t('TaskResponsible')}
      </Typography.Text>
    </>
  );

  return (
    <SelectEmployee
      name={RESPONSIBLE}
      label={label}
      isDisabled={isDisabled}
      hideWorkspace
      rules={{
        required: t('RequiredField', { ns: 'Errors' })
      }}
      allowSetYourself
      params={{ excludeBench: false }}
      projectId={projectId}
      {...props}
    />
  );
};

ActionTypeResponsible.propTypes = {
  projectId: PropTypes.number,
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  defaultValues: PropTypes.object
};

ActionTypeResponsible.defaultProps = {
  projectId: undefined,
  isDisabled: false,
  isClearable: true,
  defaultValues: {}
};

export default ActionTypeResponsible;
