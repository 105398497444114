import React, { useEffect, useState } from 'react';
import { Spin, Alert } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TYPE_MEETING, TYPE_MEMBERS } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import { FormEmployeeSelect } from 'components/common/hook-form';

import { fetchAssignments } from 'store/tasks/actions';

import { usePrevious } from 'hooks/common';

import styles from './members-field.module.scss';

const MembersField = ({ defaultValues, currentTaskType, label }) => {
  const dispatch = useDispatch();

  const [isLoadingAssignments, setIsLoadingAssignments] = useState(false);

  const prevTaskId = usePrevious((defaultValues || {}).id);

  const { t } = useTranslation('AddTask');

  const fetchData = async () => {
    setIsLoadingAssignments(true);
    try {
      await dispatch(
        fetchAssignments({ id: defaultValues.id, directMembersOnly: true })
      );
    } finally {
      setIsLoadingAssignments(false);
    }
  };

  useEffect(() => {
    if (
      !Object.keys(defaultValues).length ||
      (defaultValues || {}).id === prevTaskId ||
      defaultValues.assignments
    )
      return;

    fetchData();
  }, [defaultValues]);

  return (
    <div className={styles.withHint} data-qa="qa-i5xbtzxahlokbi8">
      <FormEmployeeSelect
        label={label}
        name={TYPE_MEMBERS}
        data-qa="qa-3php7du15o7y1rl"
        params={{
          excludeSelf: true
        }}
        isMulti
        reloadAfterOpen
        allowSelectAll
        showSelectedOptionsOnList={false}
      />

      {currentTaskType === TYPE_MEETING && (
        <Alert
          type="warning"
          message={t('MembersWarning')}
          style={{ fontSize: 12 }}
        />
      )}

      {isLoadingAssignments && <Spin className={styles.spin} />}
    </div>
  );
};

export default MembersField;
