import React from 'react';

import { TYPE_CO_RESPONSIBLES } from 'constants/index';

// eslint-disable-next-line import/no-cycle
import FormProjectParticipantsSelect from 'components/common/hook-form/select/project-participants';
import {
  FormEmployeeSelect,
  validateRequired
} from 'components/common/hook-form';

const ActionTypeCoResponsibles = ({ projectId, label }) => {
  const SelectEmployee = projectId
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;

  return (
    <SelectEmployee
      name={TYPE_CO_RESPONSIBLES}
      label={label}
      data-qa="qa-yyh59iy14r15ule"
      rules={{
        required: validateRequired()
      }}
      projectId={projectId}
      hideWorkspace
      isMulti
      params={{ excludeBench: false }}
    />
  );
};

export default ActionTypeCoResponsibles;
