import { useForm } from 'react-hook-form';
import moment from 'moment/moment';

import {
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_DATE_END,
  TITLE,
  TYPE_CONTACT,
  DATE_TIME_FORMAT_FM,
  RESPONSIBLE,
  SCHEDULER,
  TYPE_CO_RESPONSIBLES,
  TYPE_APPROVING_MANAGER,
  TYPE_COMPLETE_REQUIRE,
  TYPE_MEETING_CONTACTS,
  SCHEDULED_TASK_WEEK,
  SCHEDULED_TASK_TYPES,
  DESCRIPTION
} from 'constants/index';

import { getTaskDescriptionText } from 'components/tasks-view/utils';

import { mapUserToSelectValue } from 'utils/map-user-to-select';
import getTimeByEstimate from 'utils/get-time-by-estimate';

export const useFormDefaultValues = ({
  defaultValues,
  isTypeMeeting,
  isTemplate
}) => {
  const transformSteps = () =>
    (defaultValues.agreementSteps || []).map(step => ({
      ...step,
      employees: step.employees.map(e => ({ value: e.id, label: e }))
    }));

  const apartValues = getTimeByEstimate(defaultValues.estimation);

  const defaultFreqInterval =
    (defaultValues.schedulerConfig || {}).freqInterval || 1;

  const defaultFreqType =
    (defaultValues.schedulerConfig || {}).freqType || SCHEDULED_TASK_WEEK;

  const getInitialValues = () => ({
    // ...defaultValues,
    location: defaultValues?.location || '',
    locationExact: defaultValues?.locationExact || '',
    [TYPE_CO_RESPONSIBLES]: (defaultValues.coResponsibles || []).map(
      mapUserToSelectValue
    ),
    [TYPE_MEETING_CONTACTS]: (defaultValues.relations || [])
      .filter(item => item.type === TYPE_CONTACT)
      .map(item => ({
        label: {
          ...item.relation,
          firstName: item.relation.name
        },
        value: item.objectId
      })),
    [TYPE_CONTROLLER]: mapUserToSelectValue(defaultValues.controller),
    [TYPE_APPROVING_MANAGER]: defaultValues?.approvingManager
      ? {
          value: defaultValues.approvingManager.id,
          label: defaultValues.approvingManager
        }
      : undefined,
    project: defaultValues?.project
      ? {
          value: defaultValues.project.id,
          label: defaultValues.project
        }
      : undefined,
    sprint: defaultValues?.sprint
      ? {
          value: defaultValues.sprint.id,
          label: defaultValues.sprint
        }
      : undefined,
    relations: (defaultValues.relations || []).filter(r =>
      isTypeMeeting ? r.type !== TYPE_CONTACT : true
    ),
    [TYPE_COMPLETE_REQUIRE]: defaultValues?.completeRequire,
    [TITLE]: defaultValues.title,
    [DESCRIPTION]: {
      description: getTaskDescriptionText(defaultValues.description),
      fileList: defaultValues.fileList || []
    },
    [RESPONSIBLE]: Array.isArray(defaultValues?.responsible)
      ? defaultValues.responsible.map(mapUserToSelectValue)
      : mapUserToSelectValue(defaultValues?.responsible || {}),
    // [RESPONSIBLE]: [],
    agreementSteps: isTemplate
      ? defaultValues.agreementSteps
      : transformSteps(),
    [TYPE_DATE_START]:
      defaultValues?.dateStart &&
      moment(defaultValues.dateStart, DATE_TIME_FORMAT_FM).toDate(),
    [TYPE_DATE_END]:
      defaultValues?.dateEnd &&
      moment(defaultValues.dateEnd, DATE_TIME_FORMAT_FM).toDate(),
    [SCHEDULER]: defaultValues.isScheduler
      ? true
      : defaultValues[SCHEDULER] || false,
    storyPoint: defaultValues?.storyPoint,
    hours:
      defaultValues.estimation > 0 ? apartValues.hours.toString() : undefined,
    minutes:
      defaultValues.estimation > 0 ? apartValues.minutes.toString() : undefined,
    isStoryPointEstimation: defaultValues?.isStoryPointEstimation,
    isActiveScheduler: (defaultValues.schedulerConfig || {}).isActive,
    freqInterval: defaultFreqInterval,
    freqType: defaultFreqType,
    weekdays: (defaultValues.schedulerConfig || {}).weekdays || [
      SCHEDULED_TASK_TYPES[SCHEDULED_TASK_WEEK].options[moment().day()].value
    ]
  });

  const methods = useForm({
    defaultValues: getInitialValues()
  });

  return methods;
};
