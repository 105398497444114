import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Alert } from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import { union, isEqual } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { FormProvider } from 'react-hook-form';

import {
  TYPE_ASSET,
  TYPE_TASK,
  TYPE_MEMBERS,
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_DATE_END,
  TYPE_TIME,
  TYPE_ADDRESS,
  TITLE,
  DESCRIPTION,
  DATE_PICKER_TIME_FORMAT,
  TYPE_RELATIONS,
  TYPE_MEETING,
  TYPE_PROJECT,
  RESPONSIBLE,
  TYPE_AGREEMENT,
  VIDEOS,
  SCHEDULER,
  TYPE_ACQUAINTANCE,
  TYPE_TAGS,
  TYPE_CO_RESPONSIBLES,
  TYPE_APPROVING_MANAGER,
  TYPE_COMPLETE_REQUIRE,
  TYPE_AGREEMENT_TASK,
  TYPE_MEETING_CONTACTS,
  TYPE_ACCESS_TYPE,
  TASK_ACCESS_TYPE_FEATURE_FLAG,
  TYPE_CONTACT
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import VideoBtn from 'components/common/video-btn';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
// eslint-disable-next-line import/no-cycle
import {
  FormContactSelect,
  FormEmployeeSelect,
  FormInput,
  FormLocationInput,
  FormProjectParticipantsSelect,
  FormTagsSelect,
  LabelWithTooltip,
  validateRequired,
  withoutBubbling,
  validateIsNumber,
  validateMaxLength,
  FormSwitch,
  FormRelationsSelect,
  FormProjectSelect,
  FormSprintSelect,
  FormCompleteRequireTaskSelect,
  validateMinLength,
  FormDatePicker,
  FormCheckbox
} from 'components/common/hook-form';
import useMeetingCalendarContext from 'components/tasks-view/components/meeting-calendar/use-meeting-calendar-context';
import useValidityDateFileList from 'components/common/validity-date/use-validity-date-file-list';
import { CloseIcon } from 'components/common/icon/icons';
import FormNewEditor from 'components/common/hook-form/markdown';

import { getActiveWorkspace, getCompanyWorkspaces } from 'store/workspace';

import useMinMaxTime from 'hooks/common/use-min-max-time';
import { useUploadingFiles } from 'hooks/common/use-file-upload/use-uploading-files';
import { usePrevious } from 'hooks';
import alphabeticallySort from 'utils/alphabetically-sort';
import { getFullName } from 'utils/get-fio';

import ActionTypeMembers from '../../components/actions/type-members';
import ActionTypeController from '../../components/actions/type-controller';
import ActionTypeResponsible from '../../components/actions/type-responsible';
import ActionTypeCoResponsibles from '../../components/actions/type-co-responsibles';
import IntervalSelect from './interval-select';
import AgreementSteps from './agreement-steps';
import TaskTypeSelect from '../../../../components/task-type-select';
import {
  getFieldsByTypes,
  getFullFilledFields,
  isAllowedToDelete
} from '../utils';
import { AccessToTask } from '../../components/access-to-task';
import { useFormDefaultValues } from './hooks/useFormDefaultValues';
import { useDateValidationRules } from './hooks/useDateValidationRules';
import { transformSubmittedValues } from './helpers/transformSubmittedValues';

import styles from './styles.module.scss';

const { Text } = Typography;

const videoSlugByType = {
  [TYPE_ACQUAINTANCE]: VIDEOS.cardTaskAcquaintance,
  [TYPE_AGREEMENT]: VIDEOS.cardTaskAgreement
};

const MainForm = ({
  onSubmit,
  currentTaskType = TYPE_TASK,
  setCurrentTaskType,
  defaultValues = {},
  isLoading = false,
  isBacklog = false,
  isSprints = false,
  isFromTemplate,
  meetingCalendar
}) => {
  const prevValues = usePrevious(defaultValues);
  const workspaces = useSelector(getCompanyWorkspaces);
  const workspace = useSelector(getActiveWorkspace) || {};

  const [activeFields, setActiveFields] = useState([]);
  const [visibleDateStart, setVisibleDateStart] = useState(false);

  const { goToDate, selectedDates, selectDatesToCalendar, setResources } =
    useMeetingCalendarContext();

  const isAccessTypeEnabled = useFeatureIsOn(TASK_ACCESS_TYPE_FEATURE_FLAG);

  const { t } = useTranslation([
    'AddTask',
    'AddTaskType',
    'Errors',
    'TaskLinks',
    'Common'
  ]);

  const isTopTask = !(defaultValues || {}).parent;
  const isSlaTask = !!Object.values(defaultValues.taskInfo || {}).length;
  const isTypeTask = (currentTaskType || '').includes(TYPE_TASK);
  const isTypeAgreement = currentTaskType === TYPE_AGREEMENT;
  const isTypeMeeting = currentTaskType === TYPE_MEETING;
  const isTypeAcquaintence = currentTaskType === TYPE_ACQUAINTANCE;
  const hideResponsibleField =
    isTypeAgreement || isTypeAcquaintence || isTypeMeeting;

  const alertTextByType = {
    [TYPE_AGREEMENT]: t('IntroductionDocsWarning', {
      taskType: t('AgreementHeading', { ns: 'AddTaskType' })
    }),
    [TYPE_ACQUAINTANCE]: t('IntroductionDocsWarning', {
      taskType: t('IntroductionHeading', { ns: 'AddTaskType' })
    })
  };
  const formatDateValue = DATE_PICKER_TIME_FORMAT;

  const methods = useFormDefaultValues({
    defaultValues,
    isFromTemplate,
    isTypeMeeting
  });

  console.log('create currentTaskType', currentTaskType);
  console.log('create form values', methods.getValues());
  console.log('create form methods', methods);

  const relations = methods.watch('relations') || [];
  const project = methods.watch('project');
  const coResponsibles = methods.watch(TYPE_CO_RESPONSIBLES);
  const isPeriodic = methods.watch(SCHEDULER);
  const startDate = methods.watch(TYPE_DATE_START);
  const endDate = methods.watch(TYPE_DATE_END);
  const descriptionWatch = methods.watch(DESCRIPTION);
  const isStoryPointEstimation = methods.watch('isStoryPointEstimation');
  const accessTypeValue = methods.watch(TYPE_ACCESS_TYPE)?.value;
  const responsibles = methods.watch(RESPONSIBLE);

  const relatedAsset = relations.find(({ type }) => type === TYPE_ASSET);
  const projectId = (project || {}).value;
  const SelectEmployee = projectId
    ? FormProjectParticipantsSelect
    : FormEmployeeSelect;
  const isUploadingFiles = useUploadingFiles((descriptionWatch || {}).fileList);
  const isOutdated = moment(new Date()).isAfter(endDate);

  const showBacklogAlert =
    !isPeriodic &&
    !activeFields.includes(TYPE_DATE_START) &&
    (!!projectId || isBacklog) &&
    !endDate;

  const dateEndRules = useDateValidationRules({
    projectId,
    isBacklog,
    activeFields,
    isPeriodic,
    startDate
  });

  useEffect(() => {
    setVisibleDateStart(false);

    switch (currentTaskType) {
      case TYPE_MEETING:
        setVisibleDateStart(true);
        break;
      default:
        break;
    }

    const fullFilledFields = getFullFilledFields({
      values: defaultValues,
      activeFields
    });

    if (fullFilledFields.includes(TYPE_DATE_START)) setVisibleDateStart(true);

    setActiveFields(
      union(
        fullFilledFields,
        getFieldsByTypes(
          currentTaskType,
          fullFilledFields.sort(sortByCoResponsiblesAndController),
          isBacklog
        )
      )
    );
  }, [currentTaskType]);

  // When creating a task from outside, we update the form if there have been changes in initialData
  useEffect(() => {
    if (
      prevValues &&
      Object.keys(defaultValues || {}).length &&
      !isEqual(defaultValues, prevValues)
    ) {
      methods.reset();
    }
  }, [prevValues, defaultValues]);

  useEffect(() => {
    if (relatedAsset) {
      methods.setValue(SCHEDULER, false);
    }
  }, [relatedAsset]);

  useEffect(() => {
    if (selectedDates.jsEvent) {
      methods.setValue(TYPE_DATE_START, selectedDates.start);
      methods.setValue(TYPE_DATE_END, selectedDates.end);
    }
  }, [selectedDates]);

  useEffect(() => {
    if (defaultValues.dateStart && defaultValues.dateEnd) {
      selectDatesToCalendar({
        dateStart: defaultValues.dateStart,
        dateEnd: defaultValues.dateEnd
      });
    }
  }, [defaultValues.dateEnd, defaultValues.dateStart]);

  useEffect(() => {
    if (coResponsibles) {
      setResources(
        coResponsibles.map(r => ({
          id: r.value,
          title: getFullName(r.label)
        }))
      );
    }
  }, [(coResponsibles || []).length]);

  const onChangeTaskType = value => {
    setCurrentTaskType(value);
    meetingCalendar.setVisible(false);
  };

  const addField = type =>
    setActiveFields(
      [...activeFields, type].sort(sortByCoResponsiblesAndController)
    );

  const removeField = type => {
    if (type === TYPE_APPROVING_MANAGER) {
      setCurrentTaskType(TYPE_TASK);
    }
    setActiveFields(activeFields.filter(field => field !== type));
    methods.setValue(type, undefined);
    methods.clearErrors(type);

    if (type === TYPE_DATE_START) {
      methods.clearErrors(TYPE_DATE_END);
    }
  };

  const handleSubmit = async value => {
    const isValidValidityDates = validateValidityDateStateValues();

    if (!isValidValidityDates) {
      return;
    }

    if (
      projectId &&
      !value.dateStart &&
      !value.dateEnd &&
      !methods.watch(SCHEDULER) &&
      !activeFields.includes(TYPE_APPROVING_MANAGER)
    ) {
      methods.setValue(TYPE_DATE_END, undefined);
    }

    const transformedValues = transformSubmittedValues({
      values: value,
      activeFields,
      currentTaskType,
      isTopTask,
      defaultValues
    });

    await onSubmit(transformedValues);

    methods.reset();
  };

  useEffect(() => {
    methods.setValue('saveAsTemplate', isPeriodic);
    methods.setValue('updateTemplate', isPeriodic);
  }, [isPeriodic]);

  const [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate,
    endDate
  });

  const changeDateEnd = date => {
    const result =
      !endDate && !moment(date).toDate().getHours()
        ? new Date(moment(date).toDate().setHours(23, 45))
        : date;

    if (!startDate) {
      goToDate(result);
    }

    selectDatesToCalendar({ dateStart: startDate, dateEnd: result });

    return result;
  };

  const { validateValidityDateStateValues, changeValidityDateStateValue } =
    useValidityDateFileList({
      fileList: (descriptionWatch || {}).fileList,
      onChange: updatedFileList =>
        methods.setValue(DESCRIPTION, {
          ...descriptionWatch,
          fileList: updatedFileList
        })
    });

  const handleChangeStoryPointEstimation = value => {
    if (value) {
      methods.setValue('storyPoint', undefined, { shouldValidate: true });
    } else {
      methods.setValue('hours', null, { shouldValidate: true });
      methods.setValue('minutes', null, { shouldValidate: true });
    }
  };

  const actions = [
    {
      type: TYPE_DATE_START,
      title: t(isTypeMeeting ? 'StartDateMeet' : 'StartDate'),
      onClick: () => {
        addField(TYPE_DATE_START);
        setVisibleDateStart(true);
      },
      icon: 'calendar',
      disabled: false,
      allow: true,
      visibility: true
    },
    {
      type: TYPE_TAGS,
      title: t('Tags'),
      onClick: () => addField(TYPE_TAGS),
      icon: 'tags',
      allow: true,
      visibility: true,
      component: <FormTagsSelect label={t('Tags')} name={TYPE_TAGS} />
    },
    {
      type: TYPE_ADDRESS,
      title: t('AddressHeading'),
      onClick: () => addField(TYPE_ADDRESS),
      icon: 'environment',
      allow: true,
      visibility: isTypeTask || isTypeMeeting,
      component: (
        <div className={styles.flexWrap} data-qa="qa-3egzsuhauo6xtmv">
          <FormLocationInput
            label={t('MainAddress')}
            name="location"
            itemProps={{ className: styles.location }}
            data-qa="qa-7y5as2qhq6pcy6a"
          />

          <FormInput
            label={t('AdditionalAddress')}
            name="locationExact"
            rules={{
              maxLength: validateMaxLength(25)
            }}
            autoComplete="off"
            data-qa="qa-12jnhs75vabmxwz"
            allowClear={false}
          />
        </div>
      ),
      removeFieldCallback: () => {
        methods.setValue('location', '');
        methods.setValue('locationExact', '', { shouldValidate: true });
      }
    },
    {
      type: TYPE_MEMBERS,
      title: t('Coexecutors'),
      onClick: () => addField(TYPE_MEMBERS),
      icon: 'big-team',
      allow: true,
      visibility: true,
      component: (
        <div className={styles.members} data-qa="qa-ssr3ysl0qog09qb">
          <ActionTypeMembers
            currentTaskType={currentTaskType}
            projectId={projectId}
            defaultValues={defaultValues}
          />
        </div>
      )
    },
    {
      type: TYPE_CO_RESPONSIBLES,
      allow: true,
      visibility: isTypeAcquaintence || isTypeMeeting,
      component: (
        <ActionTypeCoResponsibles
          projectId={projectId}
          label={t(isTypeAcquaintence ? 'Acquainted' : 'Employees')}
        />
      )
    },
    {
      type: TYPE_MEETING_CONTACTS,
      allow: true,
      visibility: isTypeMeeting,
      component: (
        <>
          <FormContactSelect
            name={TYPE_MEETING_CONTACTS}
            label={t('ContactsOptional')}
            isMulti
            closeMenuOnSelect={false}
          />

          <Alert
            type="warning"
            message={t('MembersWarning')}
            style={{ marginBottom: 10 }}
          />
        </>
      )
    },
    {
      type: TYPE_CONTROLLER,
      title: t('Controller'),
      allow: true,
      onClick: () => addField(TYPE_CONTROLLER),
      icon: 'user-add',
      visibility: !isTypeAgreement && !isTypeAcquaintence && !isTypeMeeting,
      component: <ActionTypeController projectId={projectId} />,
      removeFieldCallback: () => {
        methods.setValue(TYPE_CONTROLLER, null);
      }
    },
    {
      type: TYPE_APPROVING_MANAGER,
      title: t('Approving'),
      allow: true,
      onClick: () => {
        addField(TYPE_APPROVING_MANAGER);
        setCurrentTaskType(TYPE_AGREEMENT_TASK);
      },
      icon: 'audit',
      visibility: isTypeTask,
      component: (
        <SelectEmployee
          name={TYPE_APPROVING_MANAGER}
          label={t('Approving')}
          projectId={projectId}
          data-qa="qa-ne5fq02z4q1i2bd"
          rules={{
            validate: {
              required: value => {
                if (
                  !value?.value &&
                  activeFields.includes(TYPE_APPROVING_MANAGER)
                )
                  return validateRequired();
                return true;
              }
            }
          }}
        />
      ),
      removeFieldCallback: () => {
        methods.setValue(TYPE_APPROVING_MANAGER, null, {
          shouldValidate: true
        });
      }
    },
    {
      type: TYPE_TIME,
      title: t('EstimatedTime'),
      onClick: () => addField(TYPE_TIME),
      icon: 'timer',
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: -10
      },
      component: (
        <>
          <Text
            className={classnames(styles.subTitle, styles.block)}
            data-qa="qa-5d3rzy2j6qplqgy"
          >
            {t('EstimatedTime')}
          </Text>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              marginTop: 16
            }}
          >
            {isStoryPointEstimation ? (
              <FormInput
                label={t('Storypoints')}
                name="storyPoint"
                rules={{
                  pattern: validateIsNumber()
                }}
                size="default"
                placeholder="000"
              />
            ) : (
              <div className={styles.timeset} data-qa="qa-pz7tabse7kerbw0">
                <FormInput
                  label={t('Hours')}
                  name="hours"
                  rules={{
                    pattern: validateIsNumber(),
                    maxLength: validateMaxLength(3)
                  }}
                  size="default"
                  placeholder="000"
                  data-qa="qa-l2xkyoh6t3se8nj"
                />

                <FormInput
                  label={t('Minutes')}
                  name="minutes"
                  rules={{
                    pattern: validateIsNumber(),
                    maxLength: validateMaxLength(3)
                  }}
                  size="default"
                  placeholder="000"
                  data-qa="qa-l2xkyoh6t3se8nj"
                />
              </div>
            )}

            {!defaultValues.parent && (
              <FormSwitch
                name="isStoryPointEstimation"
                label={t('ChangeToStorypointsToggle')}
                itemProps={{
                  style: { margin: '27px 0 0 0', width: 'auto' }
                }}
                callback={value => handleChangeStoryPointEstimation(value)}
              />
            )}
          </div>
        </>
      ),
      removeFieldCallback: () => {
        methods.setValue('storyPoint', undefined, { shouldValidate: true });
        methods.setValue('hours', null, { shouldValidate: true });
        methods.setValue('minutes', null, { shouldValidate: true });
        methods.setValue('isStoryPointEstimation', false);
      }
    },
    {
      type: TYPE_RELATIONS,
      title: t('LinksHeading'),
      onClick: () => addField(TYPE_RELATIONS),
      icon: 'link',
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: -8
      },
      component: (
        <>
          <Text
            className={classnames(styles.subTitle, styles.block)}
            style={{ marginBottom: 16 }}
            data-qa="qa-tolj1gemcntd5ei"
          >
            {t('LinksHeading')}
          </Text>

          <FormRelationsSelect
            name="relations"
            label={
              <LabelWithTooltip
                label={t('LinkType', { ns: 'TaskLinks' })}
                tooltip={t('TaskLinksDesc', { ns: 'TaskLinks' })}
              />
            }
            itemProps={{
              className: styles.relationsSelect
            }}
            hiddenTypes={isTypeMeeting ? [TYPE_CONTACT] : []}
          />
        </>
      )
    },
    {
      type: TYPE_PROJECT,
      title: t('Project'),
      onClick: () => addField(TYPE_PROJECT),
      icon: 'project',
      allow: true,
      visibility: isTopTask,
      deleteBtnStyle: {
        top: -12
      },
      component: (
        <>
          <Text className={classnames(styles.subTitle, styles.block)}>
            {t('Project')}
          </Text>

          <div className={styles.projectFields}>
            <FormProjectSelect
              name="project"
              label={t('Project')}
              params={{
                isActive: true
              }}
              callback={() => methods.setValue('sprint', null)}
            />

            {project?.label?.sprintsCount > 0 && isTypeTask && (
              <FormSprintSelect
                name="sprint"
                label={t('Sprint')}
                params={{
                  project: projectId,
                  status: ['active', 'planned'],
                  isLag: false
                }}
              />
            )}
          </div>
        </>
      ),
      removeFieldCallback: () => {
        methods.setValue('project', undefined);
        methods.setValue('sprint', undefined);
      }
    },
    {
      type: TYPE_COMPLETE_REQUIRE,
      title: t('TaskResult'),
      onClick: () => addField(TYPE_COMPLETE_REQUIRE),
      icon: 'diff',
      allow: true,
      visibility: isTypeTask,
      component: (
        <FormCompleteRequireTaskSelect
          label={t('TaskResultHeading')}
          name={TYPE_COMPLETE_REQUIRE}
        />
      )
    }
  ].sort((a, b) => alphabeticallySort(a, b, 'title'));

  actions.push({
    type: TYPE_ACCESS_TYPE,
    title: t('AccessToTask'),
    onClick: () => addField(TYPE_ACCESS_TYPE),
    icon: 'lock',
    allow: isAccessTypeEnabled && isTopTask,
    visibility:
      isTypeTask || isTypeAgreement || isTypeMeeting || isTypeAcquaintence,
    component: (
      <AccessToTask
        isDisabled={!!defaultValues.parentAccessType && isFromTemplate}
        projectId={projectId}
        accessTypeValue={accessTypeValue}
      />
    )
  });

  const renderTaskCreatedAlert = () =>
    workspaces.length > 1 && (
      <Alert
        type="warning"
        message={
          <Trans
            i18nKey="WorkspaceTaskWarning"
            ns="AddTask"
            components={{
              bold: <Text size="small" strong />
            }}
            values={{
              workspaceTitle: workspace.title
            }}
          />
        }
        style={{ fontSize: 12, marginBottom: 15 }}
      />
    );

  const renderTitle = () => (
    <div className={styles.field} data-qa="qa-bjc7ya7fwudhyna">
      <FormInput
        name={TITLE}
        itemProps={{
          className: styles.field
        }}
        label={t('TaskName')}
        rules={{
          required: validateRequired(),
          minLength: validateMinLength(2)
        }}
        className={styles.title}
        autoFocus
        autoComplete="off"
      />
    </div>
  );

  const renderDescription = () => (
    <>
      <div
        className={classnames(styles.field, styles.description)}
        data-qa="qa-kpga5cdf9m2eks8"
      >
        <FormNewEditor
          name={DESCRIPTION}
          label={t('TaskDescriptionMobile')}
          placeholder={t('TaskDescriptionPlchldr')}
          actionsDeps={{
            entityType: TYPE_TASK
          }}
          resizeInput
          allowManageSubscribers
          hideSubscribersAction
          hideValidityDateAction
          validityDateDeps={{ changeValidityDateStateValue }}
          highlightAttachmentsBackground
          mentionClassName={styles.mentionWrap}
        />
      </div>

      {!!alertTextByType[currentTaskType] && (
        <Alert
          type="warning"
          message={alertTextByType[currentTaskType]}
          style={{ marginBottom: 16 }}
        />
      )}
    </>
  );

  const renderResponsible = useCallback(
    () => (
      <>
        <ActionTypeResponsible projectId={projectId} isMulti />

        {(responsibles || []).length > 1 && (
          <Alert
            type="warning"
            message={t('SeveralResponsiblesWarning')}
            style={{ fontSize: 12, marginBottom: 15 }}
          />
        )}
      </>
    ),
    [projectId, defaultValues.responsible, workspace.user, responsibles]
  );

  const renderDates = () => (
    <div className={classnames(styles.dates)} data-qa="qa-i2p5c3lsddlcdgk">
      <div className={styles.dateContainer} data-qa="qa-n862olj2yefwsuv">
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          {visibleDateStart && (
            <div className={styles.dateStart} data-qa="qa-el2pguhp7736pmj">
              <FormDatePicker
                name={TYPE_DATE_START}
                label={t(isTypeMeeting ? 'StartDateMeet' : 'StartDate')}
                rules={{
                  required: {
                    value: projectId || isTypeMeeting || isPeriodic,
                    message: validateRequired()
                  }
                }}
                showTimeSelect
                timeIntervals={15}
                wrapperClassname={styles.datePicker}
                dateFormat={formatDateValue}
                minDate={new Date()}
                maxDate={endDate}
                minTime={minTimeStart}
                maxTime={maxTimeStart}
                callback={date => {
                  selectDatesToCalendar({
                    dateStart: date,
                    dateEnd: endDate
                  });
                  goToDate(date);
                }}
                defaultToMidnight={isTypeMeeting}
              />

              {isAllowedToDelete(TYPE_DATE_START, currentTaskType) &&
                !isPeriodic && (
                  <Button
                    icon={<CloseIcon />}
                    type="text"
                    className={styles.btnDelete}
                    data-qa="qa-je9cb5tlkwj268k"
                    onClick={() => {
                      removeField(TYPE_DATE_START);
                      setVisibleDateStart(false);
                    }}
                  />
                )}
            </div>
          )}

          <FormDatePicker
            name={TYPE_DATE_END}
            label={t(isTypeMeeting ? 'DueDateMeet' : 'TaskDueDate')}
            itemProps={{
              style: { maxWidth: 180 }
            }}
            rules={dateEndRules}
            showTimeSelect
            timeIntervals={15}
            wrapperClassname={styles.datePicker}
            dateFormat={formatDateValue}
            minDate={startDate || new Date()}
            maxDate={
              defaultValues.maxDateEnd &&
              moment(defaultValues.maxDateEnd).toDate()
            }
            disabled={isSlaTask}
            minTime={minTimeEnd}
            maxTime={maxTimeEnd}
            openToDate={isOutdated ? new Date() : undefined}
            callback={changeDateEnd}
          />
        </div>

        {!isTypeAgreement && (
          <FormCheckbox
            name={SCHEDULER}
            className={styles.checkbox}
            itemProps={{
              style: {
                marginLeft: 'auto',
                marginTop: 25
              }
            }}
            data-qa="qa-3tq9cu8wjxwuxol"
            disabled={relatedAsset || isSlaTask}
            callback={() => {
              addField(TYPE_DATE_START);
              setVisibleDateStart(true);
            }}
          >
            {t('RepeatTaskChckbx')}
          </FormCheckbox>
        )}
      </div>

      {isSlaTask && (
        <Alert
          type="warning"
          message={t('SLADateWarning')}
          className={styles.slaAlert}
        />
      )}

      {showBacklogAlert && (
        <Alert
          type="warning"
          message={t('BacklogWarning')}
          style={{ fontSize: 12, marginBottom: 8 }}
        />
      )}

      {isPeriodic && <IntervalSelect defaultValues={defaultValues} />}
    </div>
  );

  const renderAgreementSteps = () => {
    if (isTypeAgreement) {
      return <AgreementSteps />;
    }

    return null;
  };

  const filteredActions = actions
    .filter(a => !activeFields.includes(a.type))
    .filter(action => action.visibility);

  const sortByCoResponsiblesAndController = (a, b) => {
    if (a === TYPE_CONTROLLER || a === TYPE_CO_RESPONSIBLES) {
      return -1;
    }
    if (b === TYPE_CONTROLLER || b === TYPE_CO_RESPONSIBLES) {
      return 1;
    }
    return 0;
  };

  return (
    <FormProvider {...methods}>
      <form
        className={styles.root}
        data-qa="qa-ubmmun9vwsxz5du"
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(handleSubmit))
        }
      >
        <div className={styles.typeTaskSelect} data-qa="qa-x9zm1bibr51m508">
          <Text className={styles.subTitle} data-qa="qa-70ihlphbudp36lp">
            {t('TaskTypeHeading')}
          </Text>

          <div className={styles.select} data-qa="qa-y604ax0rpr13ui4">
            <TaskTypeSelect
              onChange={onChangeTaskType}
              data-qa="qa-95kighxbsvlzir4"
              onlyAllowedTypes
              disabled={
                isBacklog ||
                isSprints ||
                defaultValues.isDisabledTypeSelect ||
                relatedAsset ||
                isSlaTask
              }
              defaultValue={
                currentTaskType === TYPE_AGREEMENT_TASK
                  ? TYPE_TASK
                  : currentTaskType
              }
            />
          </div>
        </div>

        <div className={styles.fieldsWrap} data-qa="qa-qekax3a8aav153d">
          <VideoBtn
            slug={videoSlugByType[currentTaskType] || VIDEOS.cardTask}
            style={{ marginBottom: 10 }}
          />

          {renderTaskCreatedAlert()}

          {renderTitle()}

          {renderDescription()}

          {renderDates()}

          {isTypeMeeting && (
            <Button
              type="secondary"
              size="large"
              className={styles.visibleCalendarBtn}
              onClick={() =>
                meetingCalendar.setVisible(!meetingCalendar.visible)
              }
            >
              {t(
                meetingCalendar.visible ? 'HideCalendarBtn' : 'OpenCalendarBtn'
              )}
            </Button>
          )}

          {!hideResponsibleField && renderResponsible()}

          {renderAgreementSteps()}

          {(activeFields || []).map(field => {
            const action = actions
              .filter(item => item.visibility)
              .find(item => item.type === field);

            if (field !== TYPE_DATE_START) {
              return (
                <div
                  key={field}
                  className={styles.field}
                  data-qa="qa-h5ztwdjpszrlnx0"
                >
                  {action && action.component}

                  {isAllowedToDelete(field, currentTaskType) && (
                    <Button
                      type="text"
                      icon={<CloseIcon />}
                      className={styles.btnDelete}
                      style={(action || {}).deleteBtnStyle}
                      data-qa="qa-yau3zi7shuxwodz"
                      onClick={() => {
                        removeField(field);
                        action?.removeFieldCallback?.();
                      }}
                    />
                  )}
                </div>
              );
            }

            return undefined;
          })}
        </div>

        <Divider className={styles.divider} data-qa="qa-sb8qczoewi8j9t7" />

        <ActionsDropdown
          actions={filteredActions}
          placement="bottomLeft"
          btnClassName={styles.actionsWrap}
          data-qa="qa-ul9pdpfvyr1qaf2"
          overlayClassName={styles.dropdownOverlay}
        >
          <Button
            type="link"
            weight="bold"
            className={styles.btnDropdown}
            data-qa="qa-s6038pwmz8o0h26"
          >
            <Icon type="plus" /> {t('ShowAllFieldsBtn')}
          </Button>
        </ActionsDropdown>

        <div className={styles.footerActions} data-qa="qa-ia26ypbtpanka41">
          {isFromTemplate && !defaultValues.isScheduler ? (
            <FormCheckbox name="updateTemplate" disabled={isPeriodic}>
              {t('UpdateTemplateChckbx')}
            </FormCheckbox>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <FormCheckbox name="saveAsTemplate" disabled={isPeriodic}>
                {t('SaveAsTemplateChckbx')}
              </FormCheckbox>

              <VideoBtn slug={VIDEOS.whatIsTemplate} style={{ marginTop: 5 }} />
            </div>
          )}

          <Button
            style={{ marginLeft: 'auto', minWidth: 130 }}
            htmlType="submit"
            type="primary"
            data-qa="qa-1ffnvx0iym4t05o"
            size="large"
            disabled={isLoading || isUploadingFiles}
            loading={isLoading || isUploadingFiles}
          >
            {t('CreateBtn')}{' '}
            {isUploadingFiles && ` ${t('FileLoading', { ns: 'Common' })}`}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default MainForm;
