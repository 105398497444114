import React from 'react';
import { useTranslation } from 'react-i18next';

import { TYPE_MEMBERS } from 'constants/index';

import Typography from 'components/common/typography';
import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';
// eslint-disable-next-line import/no-cycle
import FormProjectParticipantsSelect from 'components/common/hook-form/select/project-participants';

import MembersField from '../members-field';

const ActionTypeMembers = ({ projectId, defaultValues, currentTaskType }) => {
  const { t } = useTranslation(['AddTask', 'Common']);

  const label = (
    <>
      <Typography.Text
        style={{ color: 'inherit', marginRight: 5 }}
        size="small"
      >
        {t('Coexecutors')}
      </Typography.Text>

      <Tooltip title={t('CoexecutorsTip')}>
        <Icon
          type="question-circle"
          size={12}
          style={{ verticalAlign: 'unset' }}
        />
      </Tooltip>
    </>
  );

  if (projectId) {
    return (
      <FormProjectParticipantsSelect
        name={TYPE_MEMBERS}
        label={label}
        projectId={projectId}
        placeholder={t('SelectMembers', { ns: 'Common' })}
        hideWorkspace
        isMulti
      />
    );
  }

  return (
    <MembersField
      defaultValues={defaultValues}
      currentTaskType={currentTaskType}
      data-qa="qa-8bwsnl9b4gr58s6"
      label={label}
    />
  );
};

export default ActionTypeMembers;
