import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';

import Icon from 'components/common/icon';
import Tooltip from 'components/common/tooltip';
import Typography from 'components/common/typography';

import getFIO from 'utils/get-fio';

import avatar1 from './avatars/1.svg';
import avatar2 from './avatars/2.svg';
import avatar3 from './avatars/3.svg';
import avatar4 from './avatars/4.svg';
import avatar5 from './avatars/5.svg';
import avatar6 from './avatars/6.svg';
import avatar7 from './avatars/7.svg';
import avatar8 from './avatars/8.svg';
import avatar9 from './avatars/9.svg';
import avatar10 from './avatars/10.svg';
import Avatar from '../index';

export const FUNNY_AVATARS = [
  avatar10,
  avatar1,
  avatar2,
  avatar3,
  avatar4,
  avatar5,
  avatar6,
  avatar7,
  avatar8,
  avatar9
];

const ContactAvatar = ({ contact, size, tooltip, ...props }) => {
  const src = contact.employee
    ? (contact.avatarFile || {}).url
    : FUNNY_AVATARS[contact.id % 10];

  const TooltipTitle = () => (
    <div className={tooltip.className}>
      {tooltip.content.company ? (
        <>
          <Typography.Text strong>{tooltip.content.company}</Typography.Text>
          <br />
        </>
      ) : null}
      <Typography.Text>{tooltip.content.title}</Typography.Text>
    </div>
  );

  return (
    <Badge
      offset={[0, 24]}
      count={
        contact.isDeferred ? (
          <Icon type="clock-circle" size={12} color="yellow" theme="filled" />
        ) : undefined
      }
    >
      <Tooltip title={tooltip.isHide ? null : <TooltipTitle />} placement="top">
        <Avatar src={src} size={size} {...props}>
          {getFIO(contact)}
        </Avatar>
      </Tooltip>
    </Badge>
  );
};

ContactAvatar.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.number,
    avatarFile: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    employee: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
  }),
  size: PropTypes.number,
  tooltip: PropTypes.shape({
    content: PropTypes.shape({
      title: PropTypes.string,
      company: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    }),
    isHide: PropTypes.bool,
    className: PropTypes.string
  })
};

ContactAvatar.defaultProps = {
  contact: {},
  size: 24,
  tooltip: {
    className: '',
    content: {
      title: '',
      company: {}
    },
    isHide: true
  }
};

export default ContactAvatar;
