import React, { forwardRef, useCallback, useState } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { fetchEmployeesLocal } from 'store/team/employees';

import WithYourselfButton from '../../with-yorself-button';
import UserOption from '../custom-select/user-option';
import CustomSelect from '../custom-select';
import Modal from './modal';

const mapEmployee = employee => ({
  value: employee.id,
  label: employee
});

export const EmployeeSelect = forwardRef(
  (
    {
      allowSetYourself,
      allowCreatable,
      rootClassName,
      isHideManagers,
      departmentsParams,
      params: employeesParams,
      topContent = null,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('Common');
    const dispatch = useDispatch();
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleDropdownMenu, setVisibleDropdownMenu] = useState(false);

    const fetchData = useCallback(
      params =>
        dispatch(
          fetchEmployeesLocal({
            lite: true,
            isManager:
              isHideManagers !== undefined ? !isHideManagers : undefined,
            cancelable: true,
            ...params
          })
        ).then(({ entries, ...res }) => ({
          ...res,
          entries: entries.map(mapEmployee)
        })),
      [dispatch, isHideManagers]
    );

    const onClickOrgstructure = useCallback(() => {
      setVisibleModal(true);
      setVisibleDropdownMenu(true);

      setTimeout(() => {
        setVisibleDropdownMenu(false);
      }, [10]);
    }, []);

    const onSelect = selectedValue => props.onChange(selectedValue);

    const getSelectedKeys = useCallback(() => {
      if (props.isMulti && Array.isArray(props.value)) {
        return (props.value || []).map(item => item.value.toString());
      }

      return props.value && props.value.value
        ? [props.value.value.toString()]
        : [];
    }, [props.value, props.isMulti]);

    const showCreatable = useCallback(
      ({ search, items }) => {
        if (search.length && allowCreatable) {
          if (items.findIndex(item => item.label.email === search) === -1) {
            return true;
          }
        }

        return false;
      },
      [allowCreatable]
    );

    return (
      <>
        <WithYourselfButton
          value={props.value}
          onChange={props.onChange}
          isMulti={props.isMulti}
          isDisabled={props.isDisabled}
          allowSetYourself={allowSetYourself}
          className={rootClassName}
        >
          <CustomSelect
            ref={ref}
            isAsync
            isSearchable
            isClearable
            placeholder={t('SearchForEmployees')}
            valueText={t('EnterYourNameEmail')}
            closeMenuOnSelect={!props.isMulti}
            showSelectedOptionsOnList={false}
            fetchData={fetchData}
            Option={UserOption}
            style={{ width: '100%' }}
            showCreatable={showCreatable}
            visibleDropdownMenu={visibleDropdownMenu}
            renderContentTop={() => (
              <>
                {topContent}

                <Button
                  type="link"
                  style={{
                    textAlign: 'left',
                    minHeight: 40,
                    paddingLeft: 10,
                    marginTop: -10
                  }}
                  onClick={onClickOrgstructure}
                >
                  {t('ShowOrgStructure')}
                </Button>
              </>
            )}
            params={employeesParams}
            {...props}
          />
        </WithYourselfButton>

        <Modal
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
          callbackAfterClose={() => setVisibleDropdownMenu(true)}
          onSelect={onSelect}
          isMulti={props.isMulti}
          defaultSelectedKeys={getSelectedKeys()}
          isHideManagers={isHideManagers}
          departmentsParams={departmentsParams}
          employeesParams={employeesParams}
        />
      </>
    );
  }
);

EmployeeSelect.propTypes = {
  allowSetYourself: PropTypes.bool,
  allowCreatable: PropTypes.bool,
  rootClassName: PropTypes.string,
  isHideManagers: PropTypes.bool,
  departmentsParams: PropTypes.object,
  topContent: PropTypes.element
};

EmployeeSelect.defaultProps = {
  allowSetYourself: true,
  allowCreatable: false,
  rootClassName: undefined,
  isHideManagers: undefined,
  departmentsParams: {},
  topContent: null
};

export default EmployeeSelect;
