import React from 'react';
import { Translation, useTranslation } from 'react-i18next';

import {
  ACCESS_TYPE_PRIVATE,
  ACCESS_TYPE_PUBLIC,
  ACCESS_TYPE_RESTRICTED,
  ACCESS_TYPES_OPTIONS,
  TYPE_ACCESS_TYPE
} from 'constants/index';

import Typography from 'components/common/typography';
// eslint-disable-next-line import/no-cycle
import { FormCustomSelect } from 'components/common/hook-form';

export const AccessToTask = ({ isDisabled, projectId, accessTypeValue }) => {
  const { t } = useTranslation('AddTask');

  const hintTextByValue = {
    [ACCESS_TYPE_PUBLIC]: {
      label: projectId
        ? 'AvailableToEveryoneDescription'
        : 'PublicWithoutProject',
      ns: 'AddTask'
    },
    [ACCESS_TYPE_RESTRICTED]: {
      label: projectId ? 'LimitedDescription' : 'RestrictedWithoutProject',
      ns: 'AddTask'
    },
    [ACCESS_TYPE_PRIVATE]: {
      label: projectId ? 'PrivateDescription' : 'PrivateWithoutProject',
      ns: 'AddTask'
    }
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <FormCustomSelect
        name={TYPE_ACCESS_TYPE}
        label={t('AccessToTask')}
        disabled={isDisabled}
        options={ACCESS_TYPES_OPTIONS}
        itemProps={{
          style: { marginBottom: 0 }
        }}
      />

      {accessTypeValue && (
        <Typography.Text size="small" color="black-55">
          <Translation ns={hintTextByValue[accessTypeValue].ns}>
            {translate => translate(hintTextByValue[accessTypeValue].label)}
          </Translation>
        </Typography.Text>
      )}
    </div>
  );
};
