import React from 'react';
import { Switch as AntSwitch, Button } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './switch.module.scss';

const Switch = ({
  label,
  rootClassName,
  btnClassName,
  value,
  onChange,
  disabled,
  callback,
  ...props
}) => {
  const handleChange = event => {
    onChange(event);
    callback?.(event);
  };

  return (
    <div
      className={classnames(styles.flexSwitch, rootClassName)}
      data-qa="qa-qei6roil5i2okti"
    >
      <AntSwitch
        disabled={disabled}
        checked={value}
        onChange={handleChange}
        {...props}
      />

      {label && (
        <Button
          type="text"
          className={classnames(styles.btn, btnClassName)}
          disabled={disabled}
          onClick={() => {
            onChange(!value);
            callback?.(!value);
          }}
          data-qa="qa-rugw429mtchkeeq"
        >
          {label}
        </Button>
      )}
    </div>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  rootClassName: PropTypes.string,
  btnClassName: PropTypes.string
};

Switch.defaultProps = {
  label: undefined,
  value: undefined,
  onChange: () => {},
  rootClassName: undefined,
  btnClassName: undefined
};

export default Switch;
