import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { fetchAnnotatedParticipantsLocal } from 'store/projects';

import CustomSelect from '../custom-select';
import UserOption from '../custom-select/user-option';
import WithYourselfButton from '../../with-yorself-button';

const mapValue = value => ({
  value: value.id,
  label: value
});

export const ProjectParticipantsSelect = forwardRef(
  ({ allowSetYourself, rootClassName, ...props }, ref) => {
    const dispatch = useDispatch();

    const { t } = useTranslation('Common');

    const fetchData = params =>
      dispatch(
        fetchAnnotatedParticipantsLocal({ ...params, id: props.projectId })
      ).then(({ entries, ...res }) => ({
        ...res,
        entries: (entries || []).map(mapValue)
      }));

    return (
      <WithYourselfButton
        value={props.value}
        onChange={props.onChange}
        isMulti={props.isMulti}
        isDisabled={props.isDisabled}
        allowSetYourself={allowSetYourself}
        className={rootClassName}
      >
        <CustomSelect
          ref={ref}
          fetchData={fetchData}
          Option={UserOption}
          isAsync
          isSearchable
          isClearable
          placeholder={t('SearchForEmployees')}
          showSelectedOptionsOnList={false}
          hasRelatedProject
          style={{ width: '100%' }}
          closeMenuOnSelect={false}
          {...props}
        />
      </WithYourselfButton>
    );
  }
);

ProjectParticipantsSelect.propTypes = {
  allowSetYourself: PropTypes.bool,
  rootClassName: PropTypes.string
};

ProjectParticipantsSelect.defaultProps = {
  allowSetYourself: true,
  rootClassName: undefined
};

export default ProjectParticipantsSelect;
